import React from "react";
import { Spinner } from "react-bootstrap";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';

class GrowingSpinner extends React.Component {
    state={
        basicT:"Preview"
    }
  render(){
      const {basicT} = this.state;
    return (
        <div className="border-top mt-5 pt-3">
            <h4 id="growing-spinner">Growing spinner</h4>
            <p>If you don’t fancy a border spinner, switch to the grow spinner. While it doesn’t technically spin, it does repeatedly grow!</p>
            <p>Once again, this spinner is built with <code>currentColor</code>, so you can easily change its appearance with <a href="https://v5.getbootstrap.com/docs/5.0/utilities/colors/">text color utilities</a>. Here it is in blue, along with the supported variants.</p>
            <ul className="nav nav-tabs tab-card px-3 border-bottom-0" role="tablist">
                <li className="nav-item"><a className={basicT === "Preview"?"nav-link active":"nav-link"} href="#nav-Preview1" onClick={(e)=>{e.preventDefault(); this.setState({ basicT:"Preview" }) }} >Preview</a></li>
                <li className="nav-item"><a className={basicT === "Html"?"nav-link active":"nav-link"} href="#nav-HTML1" onClick={(e)=>{e.preventDefault(); this.setState({ basicT:"Html" }) }}>HTML</a></li>
            </ul>
            <div className="card mb-3">
                <div className="card-body">
                    <div className="tab-content">
                        <div className={basicT === "Preview"?"tab-pane fade active show":"tab-pane fade"} id="nav-Preview1" role="tabpanel">
                        <Spinner animation="grow" variant="primary" />
  <Spinner animation="grow" variant="secondary" />
  <Spinner animation="grow" variant="success" />
  <Spinner animation="grow" variant="danger" />
  <Spinner animation="grow" variant="warning" />
  <Spinner animation="grow" variant="info" />
  <Spinner animation="grow" variant="light" />
  <Spinner animation="grow" variant="dark" />
                        </div>
                        <div className={basicT === "Html"?"tab-pane fade active show":"tab-pane fade"} id="nav-HTML1" role="tabpanel">
                        <SyntaxHighlighter language="javascript" className="mt-2 language-html py-2 px-2"  style={a11yLight}>
                                {`<Spinner animation="grow" variant="primary" />
<Spinner animation="grow" variant="secondary" />
<Spinner animation="grow" variant="success" />
<Spinner animation="grow" variant="danger" />
<Spinner animation="grow" variant="warning" />
<Spinner animation="grow" variant="info" />
<Spinner animation="grow" variant="light" />
<Spinner animation="grow" variant="dark" />`}
                    </SyntaxHighlighter>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default GrowingSpinner;