import React from "react";

class IconFontsCard extends React.Component {
  render(){
    return (
        <div className="card mb-3">
            <div className="card-header py-3 bg-transparent border-bottom-0">
                <h6 className="mb-0 fw-bold ">Icon Fonts</h6> 
                <p> Icons Font- Used this Syntex <code>&lt;i className="icofont-ICON_NAME"&gt;&lt;/i&gt;</code>
                    Reference site <a href="https://icofont.com/icons" rel="noreferrer" className="text-secondary" target="_blank">https://icofont.com/icons</a>
                </p>
            </div>
            <div className="card-body">
                <div className="row iconlist row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-5 row-cols-xl-6 row-cols-xxl-6 g-3">
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-angry-monster"></i>
                            </div> 
                            <span> angry-monster </span>
                        </div>
                    </div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bathtub"></i>
                            </div> 
                            <span> bathtub </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bird-wings"></i>
                            </div> 
                            <span> bird-wings </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bow"></i>
                            </div> 
                            <span> bow </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-castle"></i>
                            </div> 
                            <span> castle </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-circuit"></i>
                            </div> 
                            <span> circuit </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-crown-king"></i>
                            </div> 
                            <span> crown-king </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-crown-queen"></i>
                            </div> 
                            <span> crown-queen </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dart"></i>
                            </div> 
                            <span> dart </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-disability-race"></i>
                            </div> 
                            <span> disability-race </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-diving-goggle"></i>
                            </div> 
                            <span> diving-goggle </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-eye-open"></i>
                            </div> 
                            <span> eye-open </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-flora-flower"></i>
                            </div> 
                            <span> flora-flower </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-flora"></i>
                            </div> 
                            <span> flora </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-gift-box"></i>
                            </div> 
                            <span> gift-box </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-halloween-pumpkin"></i>
                            </div> 
                            <span> halloween-pumpkin </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hand-power"></i>
                            </div> 
                            <span> hand-power </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hand-thunder"></i>
                            </div> 
                            <span> hand-thunder </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-king-monster"></i>
                            </div> 
                            <span> king-monster </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-love"></i>
                            </div> 
                            <span> love </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-magician-hat"></i>
                            </div> 
                            <span> magician-hat </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-native-american"></i>
                            </div> 
                            <span> native-american </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-owl-look"></i>
                            </div> 
                            <span> owl-look </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-phoenix"></i>
                            </div> 
                            <span> phoenix </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-robot-face"></i>
                            </div> 
                            <span> robot-face </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-sand-clock"></i>
                            </div> 
                            <span> sand-clock </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-shield-alt"></i>
                            </div> 
                            <span> shield-alt </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ship-wheel"></i>
                            </div> 
                            <span> ship-wheel </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-skull-danger"></i>
                            </div> 
                            <span> skull-danger </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-skull-face"></i>
                            </div> 
                            <span> skull-face </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-snowmobile"></i>
                            </div> 
                            <span> snowmobile </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-space-shuttle"></i>
                            </div> 
                            <span> space-shuttle </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-star-shape"></i>
                            </div> 
                            <span> star-shape </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-swirl"></i>
                            </div> 
                            <span> swirl </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-tattoo-wing"></i>
                            </div> 
                            <span> tattoo-wing </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-throne"></i>
                            </div> 
                            <span> throne </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-tree-alt"></i>
                            </div> 
                            <span> tree-alt </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-triangle"></i>
                            </div> 
                            <span> triangle </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-unity-hand"></i>
                            </div> 
                            <span> unity-hand </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-weed"></i>
                            </div> 
                            <span> weed </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-woman-bird"></i>
                            </div> 
                            <span> woman-bird </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bat"></i>
                            </div> 
                            <span> bat </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bear-face"></i>
                            </div> 
                            <span> bear-face </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bear-tracks"></i>
                            </div> 
                            <span> bear-tracks </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bear"></i>
                            </div> 
                            <span> bear </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bird-alt"></i>
                            </div> 
                            <span> bird-alt </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bird-flying"></i>
                            </div> 
                            <span> bird-flying </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bird"></i>
                            </div> 
                            <span> bird </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-birds"></i>
                            </div> 
                            <span> birds </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bone"></i>
                            </div> 
                            <span> bone </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bull"></i>
                            </div> 
                            <span> bull </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-butterfly-alt"></i>
                            </div> 
                            <span> butterfly-alt </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-butterfly"></i>
                            </div> 
                            <span> butterfly </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-camel-alt"></i>
                            </div> 
                            <span> camel-alt </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-camel-head"></i>
                            </div> 
                            <span> camel-head </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-camel"></i>
                            </div> 
                            <span> camel </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cat-alt-1"></i>
                            </div> 
                            <span> cat-alt-1 </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cat-alt-2"></i>
                            </div> 
                            <span> cat-alt-2 </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cat-alt-3"></i>
                            </div> 
                            <span> cat-alt-3 </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cat-dog"></i>
                            </div> 
                            <span> cat-dog </span>
                        </div>
                    </div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cat-face"></i>
                            </div> 
                            <span> cat-face </span>
                        </div>
                    </div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cat"></i>
                            </div> 
                            <span> cat </span>
                        </div>
                    </div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cow-head"></i>
                            </div> 
                            <span> cow-head </span>
                        </div>
                    </div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cow"></i>
                            </div> 
                            <span> cow </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-crab"></i>
                            </div> 
                            <span> crab </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-crocodile"></i>
                            </div> 
                            <span> crocodile </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-deer-head"></i>
                            </div> 
                            <span> deer-head </span>
                        </div></div>
                    
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dog-alt"></i>
                            </div> 
                            <span> dog-alt </span>
                        </div></div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dog-barking"></i>
                            </div> 
                            <span> dog-barking </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dog"></i>
                            </div> 
                            <span> dog </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dolphin"></i>
                            </div> 
                            <span> dolphin </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-duck-tracks"></i>
                            </div> 
                            <span> duck-tracks </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-eagle-head"></i>
                            </div> 
                            <span> eagle-head </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-eaten-fish"></i>
                            </div> 
                            <span> eaten-fish </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-elephant-alt"></i>
                            </div> 
                            <span> elephant-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-elephant-head-alt"></i>
                            </div> 
                            <span> elephant-head-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-elephant-head"></i>
                            </div> 
                            <span> elephant-head </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-elephant"></i>
                            </div> 
                            <span> elephant </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-elk"></i>
                            </div> 
                            <span> elk </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-fish-1"></i>
                            </div> 
                            <span> fish-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-fish-2"></i>
                            </div> 
                            <span> fish-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-fish-3"></i>
                            </div> 
                            <span> fish-3 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-fish-4"></i>
                            </div> 
                            <span> fish-4 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-fish-5"></i>
                            </div> 
                            <span> fish-5 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-fish"></i>
                            </div> 
                            <span> fish </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-fox-alt"></i>
                            </div> 
                            <span> fox-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-fox"></i>
                            </div> 
                            <span> fox </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-frog-tracks"></i>
                            </div> 
                            <span> frog-tracks </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-frog"></i>
                            </div> 
                            <span> frog </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-froggy"></i>
                            </div> 
                            <span> froggy </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-giraffe-head-1"></i>
                            </div> 
                            <span> giraffe-head-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-giraffe-head-2"></i>
                            </div> 
                            <span> giraffe-head-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-giraffe-head"></i>
                            </div> 
                            <span> giraffe-head </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-giraffe"></i>
                            </div> 
                            <span> giraffe </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-goat-head"></i>
                            </div> 
                            <span> goat-head </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-gorilla"></i>
                            </div> 
                            <span> gorilla </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hen-tracks"></i>
                            </div> 
                            <span> hen-tracks </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-horse-head-1"></i>
                            </div> 
                            <span> horse-head-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-horse-head-2"></i>
                            </div> 
                            <span> horse-head-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-horse-head"></i>
                            </div> 
                            <span> horse-head </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-horse-tracks"></i>
                            </div> 
                            <span> horse-tracks </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-jellyfish"></i>
                            </div> 
                            <span> jellyfish </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-kangaroo"></i>
                            </div> 
                            <span> kangaroo </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-lemur"></i>
                            </div> 
                            <span> lemur </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-lion-head-1"></i>
                            </div> 
                            <span> lion-head-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-lion-head-2"></i>
                            </div> 
                            <span> lion-head-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-lion-head"></i>
                            </div> 
                            <span> lion-head </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-lion"></i>
                            </div> 
                            <span> lion </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-monkey-2"></i>
                            </div> 
                            <span> monkey-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-monkey-3"></i>
                            </div> 
                            <span> monkey-3 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-monkey-face"></i>
                            </div> 
                            <span> monkey-face </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-monkey"></i>
                            </div> 
                            <span> monkey </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-octopus-alt"></i>
                            </div> 
                            <span> octopus-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-octopus"></i>
                            </div> 
                            <span> octopus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-owl"></i>
                            </div> 
                            <span> owl </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-panda-face"></i>
                            </div> 
                            <span> panda-face </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-panda"></i>
                            </div> 
                            <span> panda </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-panther"></i>
                            </div> 
                            <span> panther </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-parrot-lip"></i>
                            </div> 
                            <span> parrot-lip </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-parrot"></i>
                            </div> 
                            <span> parrot </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-paw"></i>
                            </div> 
                            <span> paw </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pelican"></i>
                            </div> 
                            <span> pelican </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-penguin"></i>
                            </div> 
                            <span> penguin </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pig-face"></i>
                            </div> 
                            <span> pig-face </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pig"></i>
                            </div> 
                            <span> pig </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pigeon-1"></i>
                            </div> 
                            <span> pigeon-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pigeon-2"></i>
                            </div> 
                            <span> pigeon-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pigeon"></i>
                            </div> 
                            <span> pigeon </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rabbit"></i>
                            </div> 
                            <span> rabbit </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rat"></i>
                            </div> 
                            <span> rat </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rhino-head"></i>
                            </div> 
                            <span> rhino-head </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rhino"></i>
                            </div> 
                            <span> rhino </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rooster"></i>
                            </div> 
                            <span> rooster </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-seahorse"></i>
                            </div> 
                            <span> seahorse </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-seal"></i>
                            </div> 
                            <span> seal </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-shrimp-alt"></i>
                            </div> 
                            <span> shrimp-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-shrimp"></i>
                            </div> 
                            <span> shrimp </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-snail-1"></i>
                            </div> 
                            <span> snail-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-snail-2"></i>
                            </div> 
                            <span> snail-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-snail-3"></i>
                            </div> 
                            <span> snail-3 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-snail"></i>
                            </div> 
                            <span> snail </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-snake"></i>
                            </div> 
                            <span> snake </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-squid"></i>
                            </div> 
                            <span> squid </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-squirrel"></i>
                            </div> 
                            <span> squirrel </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-tiger-face"></i>
                            </div> 
                            <span> tiger-face </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-tiger"></i>
                            </div> 
                            <span> tiger </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-turtle"></i>
                            </div> 
                            <span> turtle </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-whale"></i>
                            </div> 
                            <span> whale </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-woodpecker"></i>
                            </div> 
                            <span> woodpecker </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-zebra"></i>
                            </div> 
                            <span> zebra </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-acer"></i>
                            </div> 
                            <span> brand-acer </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-adidas"></i>
                            </div> 
                            <span> brand-adidas </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-adobe"></i>
                            </div> 
                            <span> brand-adobe </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-air-new-zealand"></i>
                            </div> 
                            <span> brand-air-new-zealand </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-airbnb"></i>
                            </div> 
                            <span> brand-airbnb </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-aircell"></i>
                            </div> 
                            <span> brand-aircell </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-airtel"></i>
                            </div> 
                            <span> brand-airtel </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-alcatel"></i>
                            </div> 
                            <span> brand-alcatel </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-alibaba"></i>
                            </div> 
                            <span> brand-alibaba </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-aliexpress"></i>
                            </div> 
                            <span> brand-aliexpress </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-alipay"></i>
                            </div> 
                            <span> brand-alipay </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-amazon"></i>
                            </div> 
                            <span> brand-amazon </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-amd"></i>
                            </div> 
                            <span> brand-amd </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-american-airlines"></i>
                            </div> 
                            <span> brand-american-airlines </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-android-robot"></i>
                            </div> 
                            <span> brand-android-robot </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-android"></i>
                            </div> 
                            <span> brand-android </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-aol"></i>
                            </div> 
                            <span> brand-aol </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-apple"></i>
                            </div> 
                            <span> brand-apple </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-appstore"></i>
                            </div> 
                            <span> brand-appstore </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-asus"></i>
                            </div> 
                            <span> brand-asus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-ati"></i>
                            </div> 
                            <span> brand-ati </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-att"></i>
                            </div> 
                            <span> brand-att </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-audi"></i>
                            </div> 
                            <span> brand-audi </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-axiata"></i>
                            </div> 
                            <span> brand-axiata </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-bada"></i>
                            </div> 
                            <span> brand-bada </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-bbc"></i>
                            </div> 
                            <span> brand-bbc </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-bing"></i>
                            </div> 
                            <span> brand-bing </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-blackberry"></i>
                            </div> 
                            <span> brand-blackberry </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-bmw"></i>
                            </div> 
                            <span> brand-bmw </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-box"></i>
                            </div> 
                            <span> brand-box </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-burger-king"></i>
                            </div> 
                            <span> brand-burger-king </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-business-insider"></i>
                            </div> 
                            <span> brand-business-insider </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-buzzfeed"></i>
                            </div> 
                            <span> brand-buzzfeed </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-cannon"></i>
                            </div> 
                            <span> brand-cannon </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-casio"></i>
                            </div> 
                            <span> brand-casio </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-china-mobile"></i>
                            </div> 
                            <span> brand-china-mobile </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-china-telecom"></i>
                            </div> 
                            <span> brand-china-telecom </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-china-unicom"></i>
                            </div> 
                            <span> brand-china-unicom </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-cisco"></i>
                            </div> 
                            <span> brand-cisco </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-citibank"></i>
                            </div> 
                            <span> brand-citibank </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-cnet"></i>
                            </div> 
                            <span> brand-cnet </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-cnn"></i>
                            </div> 
                            <span> brand-cnn </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-cocal-cola"></i>
                            </div> 
                            <span> brand-cocal-cola </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-compaq"></i>
                            </div> 
                            <span> brand-compaq </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-debian"></i>
                            </div> 
                            <span> brand-debian </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-delicious"></i>
                            </div> 
                            <span> brand-delicious </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-dell"></i>
                            </div> 
                            <span> brand-dell </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-designbump"></i>
                            </div> 
                            <span> brand-designbump </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-designfloat"></i>
                            </div> 
                            <span> brand-designfloat </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-disney"></i>
                            </div> 
                            <span> brand-disney </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-dodge"></i>
                            </div> 
                            <span> brand-dodge </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-dove"></i>
                            </div> 
                            <span> brand-dove </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-drupal"></i>
                            </div> 
                            <span> brand-drupal </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-ebay"></i>
                            </div> 
                            <span> brand-ebay </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-eleven"></i>
                            </div> 
                            <span> brand-eleven </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-emirates"></i>
                            </div> 
                            <span> brand-emirates </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-espn"></i>
                            </div> 
                            <span> brand-espn </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-etihad-airways"></i>
                            </div> 
                            <span> brand-etihad-airways </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-etisalat"></i>
                            </div> 
                            <span> brand-etisalat </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-etsy"></i>
                            </div> 
                            <span> brand-etsy </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-fastrack"></i>
                            </div> 
                            <span> brand-fastrack </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-fedex"></i>
                            </div> 
                            <span> brand-fedex </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-ferrari"></i>
                            </div> 
                            <span> brand-ferrari </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-fitbit"></i>
                            </div> 
                            <span> brand-fitbit </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-flikr"></i>
                            </div> 
                            <span> brand-flikr </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-forbes"></i>
                            </div> 
                            <span> brand-forbes </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-foursquare"></i>
                            </div> 
                            <span> brand-foursquare </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-foxconn"></i>
                            </div> 
                            <span> brand-foxconn </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-fujitsu"></i>
                            </div> 
                            <span> brand-fujitsu </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-general-electric"></i>
                            </div> 
                            <span> brand-general-electric </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-gillette"></i>
                            </div> 
                            <span> brand-gillette </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-gizmodo"></i>
                            </div> 
                            <span> brand-gizmodo </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-gnome"></i>
                            </div> 
                            <span> brand-gnome </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-google"></i>
                            </div> 
                            <span> brand-google </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-gopro"></i>
                            </div> 
                            <span> brand-gopro </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-gucci"></i>
                            </div> 
                            <span> brand-gucci </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-hallmark"></i>
                            </div> 
                            <span> brand-hallmark </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-hi5"></i>
                            </div> 
                            <span> brand-hi5 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-honda"></i>
                            </div> 
                            <span> brand-honda </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-hp"></i>
                            </div> 
                            <span> brand-hp </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-hsbc"></i>
                            </div> 
                            <span> brand-hsbc </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-htc"></i>
                            </div> 
                            <span> brand-htc </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-huawei"></i>
                            </div> 
                            <span> brand-huawei </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-hulu"></i>
                            </div> 
                            <span> brand-hulu </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-hyundai"></i>
                            </div> 
                            <span> brand-hyundai </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-ibm"></i>
                            </div> 
                            <span> brand-ibm </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-icofont"></i>
                            </div> 
                            <span> brand-icofont </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-icq"></i>
                            </div> 
                            <span> brand-icq </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-ikea"></i>
                            </div> 
                            <span> brand-ikea </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-imdb"></i>
                            </div> 
                            <span> brand-imdb </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-indiegogo"></i>
                            </div> 
                            <span> brand-indiegogo </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-intel"></i>
                            </div> 
                            <span> brand-intel </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-ipair"></i>
                            </div> 
                            <span> brand-ipair </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-jaguar"></i>
                            </div> 
                            <span> brand-jaguar </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-java"></i>
                            </div> 
                            <span> brand-java </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-joomla"></i>
                            </div> 
                            <span> brand-joomla </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-kickstarter"></i>
                            </div> 
                            <span> brand-kickstarter </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-kik"></i>
                            </div> 
                            <span> brand-kik </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-lastfm"></i>
                            </div> 
                            <span> brand-lastfm </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-lego"></i>
                            </div> 
                            <span> brand-lego </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-lenovo"></i>
                            </div> 
                            <span> brand-lenovo </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-levis"></i>
                            </div> 
                            <span> brand-levis </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-lexus"></i>
                            </div> 
                            <span> brand-lexus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-lg"></i>
                            </div> 
                            <span> brand-lg </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-life-hacker"></i>
                            </div> 
                            <span> brand-life-hacker </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-linux-mint"></i>
                            </div> 
                            <span> brand-linux-mint </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-linux"></i>
                            </div> 
                            <span> brand-linux </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-lionix"></i>
                            </div> 
                            <span> brand-lionix </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-loreal"></i>
                            </div> 
                            <span> brand-loreal </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-louis-vuitton"></i>
                            </div> 
                            <span> brand-louis-vuitton </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-mac-os"></i>
                            </div> 
                            <span> brand-mac-os </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-marvel-app"></i>
                            </div> 
                            <span> brand-marvel-app </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-mashable"></i>
                            </div> 
                            <span> brand-mashable </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-mazda"></i>
                            </div> 
                            <span> brand-mazda </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-mcdonals"></i>
                            </div> 
                            <span> brand-mcdonals </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-mercedes"></i>
                            </div> 
                            <span> brand-mercedes </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-micromax"></i>
                            </div> 
                            <span> brand-micromax </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-microsoft"></i>
                            </div> 
                            <span> brand-microsoft </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-mobileme"></i>
                            </div> 
                            <span> brand-mobileme </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-mobily"></i>
                            </div> 
                            <span> brand-mobily </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-motorola"></i>
                            </div> 
                            <span> brand-motorola </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-msi"></i>
                            </div> 
                            <span> brand-msi </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-mts"></i>
                            </div> 
                            <span> brand-mts </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-myspace"></i>
                            </div> 
                            <span> brand-myspace </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-mytv"></i>
                            </div> 
                            <span> brand-mytv </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-nasa"></i>
                            </div> 
                            <span> brand-nasa </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-natgeo"></i>
                            </div> 
                            <span> brand-natgeo </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-nbc"></i>
                            </div> 
                            <span> brand-nbc </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-nescafe"></i>
                            </div> 
                            <span> brand-nescafe </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-nestle"></i>
                            </div> 
                            <span> brand-nestle </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-netflix"></i>
                            </div> 
                            <span> brand-netflix </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-nexus"></i>
                            </div> 
                            <span> brand-nexus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-nike"></i>
                            </div> 
                            <span> brand-nike </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-nokia"></i>
                            </div> 
                            <span> brand-nokia </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-nvidia"></i>
                            </div> 
                            <span> brand-nvidia </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-omega"></i>
                            </div> 
                            <span> brand-omega </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-opensuse"></i>
                            </div> 
                            <span> brand-opensuse </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-oracle"></i>
                            </div> 
                            <span> brand-oracle </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-panasonic"></i>
                            </div> 
                            <span> brand-panasonic </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-paypal"></i>
                            </div> 
                            <span> brand-paypal </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-pepsi"></i>
                            </div> 
                            <span> brand-pepsi </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-philips"></i>
                            </div> 
                            <span> brand-philips </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-pizza-hut"></i>
                            </div> 
                            <span> brand-pizza-hut </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-playstation"></i>
                            </div> 
                            <span> brand-playstation </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-puma"></i>
                            </div> 
                            <span> brand-puma </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-qatar-air"></i>
                            </div> 
                            <span> brand-qatar-air </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-qvc"></i>
                            </div> 
                            <span> brand-qvc </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-readernaut"></i>
                            </div> 
                            <span> brand-readernaut </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-redbull"></i>
                            </div> 
                            <span> brand-redbull </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-reebok"></i>
                            </div> 
                            <span> brand-reebok </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-reuters"></i>
                            </div> 
                            <span> brand-reuters </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-samsung"></i>
                            </div> 
                            <span> brand-samsung </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-sap"></i>
                            </div> 
                            <span> brand-sap </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-saudia-airlines"></i>
                            </div> 
                            <span> brand-saudia-airlines </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-scribd"></i>
                            </div> 
                            <span> brand-scribd </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-shell"></i>
                            </div> 
                            <span> brand-shell </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-siemens"></i>
                            </div> 
                            <span> brand-siemens </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-sk-telecom"></i>
                            </div> 
                            <span> brand-sk-telecom </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-slideshare"></i>
                            </div> 
                            <span> brand-slideshare </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-smashing-magazine"></i>
                            </div> 
                            <span> brand-smashing-magazine </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-snapchat"></i>
                            </div> 
                            <span> brand-snapchat </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-sony-ericsson"></i>
                            </div> 
                            <span> brand-sony-ericsson </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-sony"></i>
                            </div> 
                            <span> brand-sony </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-soundcloud"></i>
                            </div> 
                            <span> brand-soundcloud </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-sprint"></i>
                            </div> 
                            <span> brand-sprint </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-squidoo"></i>
                            </div> 
                            <span> brand-squidoo </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-starbucks"></i>
                            </div> 
                            <span> brand-starbucks </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-stc"></i>
                            </div> 
                            <span> brand-stc </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-steam"></i>
                            </div> 
                            <span> brand-steam </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-suzuki"></i>
                            </div> 
                            <span> brand-suzuki </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-symbian"></i>
                            </div> 
                            <span> brand-symbian </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-t-mobile"></i>
                            </div> 
                            <span> brand-t-mobile </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-tango"></i>
                            </div> 
                            <span> brand-tango </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-target"></i>
                            </div> 
                            <span> brand-target </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-tata-indicom"></i>
                            </div> 
                            <span> brand-tata-indicom </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-techcrunch"></i>
                            </div> 
                            <span> brand-techcrunch </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-telenor"></i>
                            </div> 
                            <span> brand-telenor </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-teliasonera"></i>
                            </div> 
                            <span> brand-teliasonera </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-tesla"></i>
                            </div> 
                            <span> brand-tesla </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-the-verge"></i>
                            </div> 
                            <span> brand-the-verge </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-thenextweb"></i>
                            </div> 
                            <span> brand-thenextweb </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-toshiba"></i>
                            </div> 
                            <span> brand-toshiba </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-toyota"></i>
                            </div> 
                            <span> brand-toyota </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-tribenet"></i>
                            </div> 
                            <span> brand-tribenet </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-ubuntu"></i>
                            </div> 
                            <span> brand-ubuntu </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-unilever"></i>
                            </div> 
                            <span> brand-unilever </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-vaio"></i>
                            </div> 
                            <span> brand-vaio </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-verizon"></i>
                            </div> 
                            <span> brand-verizon </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-viber"></i>
                            </div> 
                            <span> brand-viber </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-vodafone"></i>
                            </div> 
                            <span> brand-vodafone </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-volkswagen"></i>
                            </div> 
                            <span> brand-volkswagen </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-walmart"></i>
                            </div> 
                            <span> brand-walmart </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-warnerbros"></i>
                            </div> 
                            <span> brand-warnerbros </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-whatsapp"></i>
                            </div> 
                            <span> brand-whatsapp </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-wikipedia"></i>
                            </div> 
                            <span> brand-wikipedia </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-windows"></i>
                            </div> 
                            <span> brand-windows </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-wire"></i>
                            </div> 
                            <span> brand-wire </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-wordpress"></i>
                            </div> 
                            <span> brand-wordpress </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-xiaomi"></i>
                            </div> 
                            <span> brand-xiaomi </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-yahoobuzz"></i>
                            </div> 
                            <span> brand-yahoobuzz </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-yamaha"></i>
                            </div> 
                            <span> brand-yamaha </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-youtube"></i>
                            </div> 
                            <span> brand-youtube </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brand-zain"></i>
                            </div> 
                            <span> brand-zain </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bank-alt"></i>
                            </div> 
                            <span> bank-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bank"></i>
                            </div> 
                            <span> bank </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-barcode"></i>
                            </div> 
                            <span> barcode </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bill-alt"></i>
                            </div> 
                            <span> bill-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-billboard"></i>
                            </div> 
                            <span> billboard </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-briefcase-1"></i>
                            </div> 
                            <span> briefcase-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-briefcase-2"></i>
                            </div> 
                            <span> briefcase-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-businessman"></i>
                            </div> 
                            <span> businessman </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-businesswoman"></i>
                            </div> 
                            <span> businesswoman </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-chair"></i>
                            </div> 
                            <span> chair </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-coins"></i>
                            </div> 
                            <span> coins </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-company"></i>
                            </div> 
                            <span> company </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-contact-add"></i>
                            </div> 
                            <span> contact-add </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-files-stack"></i>
                            </div> 
                            <span> files-stack </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-handshake-deal"></i>
                            </div> 
                            <span> handshake-deal </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-id-card"></i>
                            </div> 
                            <span> id-card </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-meeting-add"></i>
                            </div> 
                            <span> meeting-add </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-money-bag"></i>
                            </div> 
                            <span> money-bag </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pie-chart"></i>
                            </div> 
                            <span> pie-chart </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-presentation-alt"></i>
                            </div> 
                            <span> presentation-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-presentation"></i>
                            </div> 
                            <span> presentation </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-stamp"></i>
                            </div> 
                            <span> stamp </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-stock-mobile"></i>
                            </div> 
                            <span> stock-mobile </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-chart-arrows-axis"></i>
                            </div> 
                            <span> chart-arrows-axis </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-chart-bar-graph"></i>
                            </div> 
                            <span> chart-bar-graph </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-chart-flow-1"></i>
                            </div> 
                            <span> chart-flow-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-chart-flow-2"></i>
                            </div> 
                            <span> chart-flow-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-chart-flow"></i>
                            </div> 
                            <span> chart-flow </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-chart-growth"></i>
                            </div> 
                            <span> chart-growth </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-chart-histogram-alt"></i>
                            </div> 
                            <span> chart-histogram-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-chart-histogram"></i>
                            </div> 
                            <span> chart-histogram </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-chart-line-alt"></i>
                            </div> 
                            <span> chart-line-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-chart-line"></i>
                            </div> 
                            <span> chart-line </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-chart-pie-alt"></i>
                            </div> 
                            <span> chart-pie-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-chart-pie"></i>
                            </div> 
                            <span> chart-pie </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-chart-radar-graph"></i>
                            </div> 
                            <span> chart-radar-graph </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-architecture-alt"></i>
                            </div> 
                            <span> architecture-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-architecture"></i>
                            </div> 
                            <span> architecture </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-barricade"></i>
                            </div> 
                            <span> barricade </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bolt"></i>
                            </div> 
                            <span> bolt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bricks"></i>
                            </div> 
                            <span> bricks </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-building-alt"></i>
                            </div> 
                            <span> building-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bull-dozer"></i>
                            </div> 
                            <span> bull-dozer </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-calculations"></i>
                            </div> 
                            <span> calculations </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cement-mix"></i>
                            </div> 
                            <span> cement-mix </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cement-mixer"></i>
                            </div> 
                            <span> cement-mixer </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-concrete-mixer"></i>
                            </div> 
                            <span> concrete-mixer </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-danger-zone"></i>
                            </div> 
                            <span> danger-zone </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-drill"></i>
                            </div> 
                            <span> drill </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-eco-energy"></i>
                            </div> 
                            <span> eco-energy </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-eco-environmen"></i>
                            </div> 
                            <span> eco-environmen </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-energy-air"></i>
                            </div> 
                            <span> energy-air </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-energy-oil"></i>
                            </div> 
                            <span> energy-oil </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-energy-savings"></i>
                            </div> 
                            <span> energy-savings </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-energy-solar"></i>
                            </div> 
                            <span> energy-solar </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-energy-water"></i>
                            </div> 
                            <span> energy-water </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-engineer"></i>
                            </div> 
                            <span> engineer </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-fire-extinguisher-alt"></i>
                            </div> 
                            <span> fire-extinguisher-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-fire-extinguisher"></i>
                            </div> 
                            <span> fire-extinguisher </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-fix-tools"></i>
                            </div> 
                            <span> fix-tools </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-fork-lift"></i>
                            </div> 
                            <span> fork-lift </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-glue-oil"></i>
                            </div> 
                            <span> glue-oil </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hammer-alt"></i>
                            </div> 
                            <span> hammer-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hammer"></i>
                            </div> 
                            <span> hammer </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-help-robot"></i>
                            </div> 
                            <span> help-robot </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-industries-1"></i>
                            </div> 
                            <span> industries-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-industries-2"></i>
                            </div> 
                            <span> industries-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-industries-3"></i>
                            </div> 
                            <span> industries-3 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-industries-4"></i>
                            </div> 
                            <span> industries-4 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-industries-5"></i>
                            </div> 
                            <span> industries-5 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-industries"></i>
                            </div> 
                            <span> industries </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-labour"></i>
                            </div> 
                            <span> labour </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-mining"></i>
                            </div> 
                            <span> mining </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-paint-brush"></i>
                            </div> 
                            <span> paint-brush </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pollution"></i>
                            </div> 
                            <span> pollution </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-power-zone"></i>
                            </div> 
                            <span> power-zone </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-radio-active"></i>
                            </div> 
                            <span> radio-active </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-recycle-alt"></i>
                            </div> 
                            <span> recycle-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-recycling-man"></i>
                            </div> 
                            <span> recycling-man </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-safety-hat-light"></i>
                            </div> 
                            <span> safety-hat-light </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-safety-hat"></i>
                            </div> 
                            <span> safety-hat </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-saw"></i>
                            </div> 
                            <span> saw </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-screw-driver"></i>
                            </div> 
                            <span> screw-driver </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-tools-1"></i>
                            </div> 
                            <span> tools-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-tools-bag"></i>
                            </div> 
                            <span> tools-bag </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-tow-truck"></i>
                            </div> 
                            <span> tow-truck </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-trolley"></i>
                            </div> 
                            <span> trolley </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-trowel"></i>
                            </div> 
                            <span> trowel </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-under-construction-alt"></i>
                            </div> 
                            <span> under-construction-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-under-construction"></i>
                            </div> 
                            <span> under-construction </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-vehicle-cement"></i>
                            </div> 
                            <span> vehicle-cement </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-vehicle-crane"></i>
                            </div> 
                            <span> vehicle-crane </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-vehicle-delivery-van"></i>
                            </div> 
                            <span> vehicle-delivery-van </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-vehicle-dozer"></i>
                            </div> 
                            <span> vehicle-dozer </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-vehicle-excavator"></i>
                            </div> 
                            <span> vehicle-excavator </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-vehicle-trucktor"></i>
                            </div> 
                            <span> vehicle-trucktor </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-vehicle-wrecking"></i>
                            </div> 
                            <span> vehicle-wrecking </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-worker"></i>
                            </div> 
                            <span> worker </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-workers-group"></i>
                            </div> 
                            <span> workers-group </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-wrench"></i>
                            </div> 
                            <span> wrench </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-afghani-false"></i>
                            </div> 
                            <span> afghani-false </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-afghani-minus"></i>
                            </div> 
                            <span> afghani-minus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-afghani-plus"></i>
                            </div> 
                            <span> afghani-plus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-afghani-true"></i>
                            </div> 
                            <span> afghani-true </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-afghani"></i>
                            </div> 
                            <span> afghani </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-baht-false"></i>
                            </div> 
                            <span> baht-false </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-baht-minus"></i>
                            </div> 
                            <span> baht-minus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-baht-plus"></i>
                            </div> 
                            <span> baht-plus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-baht-true"></i>
                            </div> 
                            <span> baht-true </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-baht"></i>
                            </div> 
                            <span> baht </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bitcoin-false"></i>
                            </div> 
                            <span> bitcoin-false </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bitcoin-minus"></i>
                            </div> 
                            <span> bitcoin-minus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bitcoin-plus"></i>
                            </div> 
                            <span> bitcoin-plus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bitcoin-true"></i>
                            </div> 
                            <span> bitcoin-true </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bitcoin"></i>
                            </div> 
                            <span> bitcoin </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dollar-flase"></i>
                            </div> 
                            <span> dollar-flase </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dollar-minus"></i>
                            </div> 
                            <span> dollar-minus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dollar-plus"></i>
                            </div> 
                            <span> dollar-plus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dollar-true"></i>
                            </div> 
                            <span> dollar-true </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dollar"></i>
                            </div> 
                            <span> dollar </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dong-false"></i>
                            </div> 
                            <span> dong-false </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dong-minus"></i>
                            </div> 
                            <span> dong-minus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dong-plus"></i>
                            </div> 
                            <span> dong-plus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dong-true"></i>
                            </div> 
                            <span> dong-true </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dong"></i>
                            </div> 
                            <span> dong </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-euro-false"></i>
                            </div> 
                            <span> euro-false </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-euro-minus"></i>
                            </div> 
                            <span> euro-minus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-euro-plus"></i>
                            </div> 
                            <span> euro-plus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-euro-true"></i>
                            </div> 
                            <span> euro-true </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-euro"></i>
                            </div> 
                            <span> euro </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-frank-false"></i>
                            </div> 
                            <span> frank-false </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-frank-minus"></i>
                            </div> 
                            <span> frank-minus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-frank-plus"></i>
                            </div> 
                            <span> frank-plus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-frank-true"></i>
                            </div> 
                            <span> frank-true </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-frank"></i>
                            </div> 
                            <span> frank </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hryvnia-false"></i>
                            </div> 
                            <span> hryvnia-false </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hryvnia-minus"></i>
                            </div> 
                            <span> hryvnia-minus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hryvnia-plus"></i>
                            </div> 
                            <span> hryvnia-plus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hryvnia-true"></i>
                            </div> 
                            <span> hryvnia-true </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hryvnia"></i>
                            </div> 
                            <span> hryvnia </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-lira-false"></i>
                            </div> 
                            <span> lira-false </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-lira-minus"></i>
                            </div> 
                            <span> lira-minus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-lira-plus"></i>
                            </div> 
                            <span> lira-plus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-lira-true"></i>
                            </div> 
                            <span> lira-true </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-lira"></i>
                            </div> 
                            <span> lira </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-peseta-false"></i>
                            </div> 
                            <span> peseta-false </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-peseta-minus"></i>
                            </div> 
                            <span> peseta-minus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-peseta-plus"></i>
                            </div> 
                            <span> peseta-plus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-peseta-true"></i>
                            </div> 
                            <span> peseta-true </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-peseta"></i>
                            </div> 
                            <span> peseta </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-peso-false"></i>
                            </div> 
                            <span> peso-false </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-peso-minus"></i>
                            </div> 
                            <span> peso-minus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-peso-plus"></i>
                            </div> 
                            <span> peso-plus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-peso-true"></i>
                            </div> 
                            <span> peso-true </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-peso"></i>
                            </div> 
                            <span> peso </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pound-false"></i>
                            </div> 
                            <span> pound-false </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pound-minus"></i>
                            </div> 
                            <span> pound-minus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pound-plus"></i>
                            </div> 
                            <span> pound-plus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pound-true"></i>
                            </div> 
                            <span> pound-true </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pound"></i>
                            </div> 
                            <span> pound </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-renminbi-false"></i>
                            </div> 
                            <span> renminbi-false </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-renminbi-minus"></i>
                            </div> 
                            <span> renminbi-minus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-renminbi-plus"></i>
                            </div> 
                            <span> renminbi-plus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-renminbi-true"></i>
                            </div> 
                            <span> renminbi-true </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-renminbi"></i>
                            </div> 
                            <span> renminbi </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-riyal-false"></i>
                            </div> 
                            <span> riyal-false </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-riyal-minus"></i>
                            </div> 
                            <span> riyal-minus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-riyal-plus"></i>
                            </div> 
                            <span> riyal-plus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-riyal-true"></i>
                            </div> 
                            <span> riyal-true </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-riyal"></i>
                            </div> 
                            <span> riyal </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rouble-false"></i>
                            </div> 
                            <span> rouble-false </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rouble-minus"></i>
                            </div> 
                            <span> rouble-minus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rouble-plus"></i>
                            </div> 
                            <span> rouble-plus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rouble-true"></i>
                            </div> 
                            <span> rouble-true </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rouble"></i>
                            </div> 
                            <span> rouble </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rupee-false"></i>
                            </div> 
                            <span> rupee-false </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rupee-minus"></i>
                            </div> 
                            <span> rupee-minus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rupee-plus"></i>
                            </div> 
                            <span> rupee-plus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rupee-true"></i>
                            </div> 
                            <span> rupee-true </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rupee"></i>
                            </div> 
                            <span> rupee </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-taka-false"></i>
                            </div> 
                            <span> taka-false </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-taka-minus"></i>
                            </div> 
                            <span> taka-minus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-taka-plus"></i>
                            </div> 
                            <span> taka-plus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-taka-true"></i>
                            </div> 
                            <span> taka-true </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-taka"></i>
                            </div> 
                            <span> taka </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-turkish-lira-false"></i>
                            </div> 
                            <span> turkish-lira-false </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-turkish-lira-minus"></i>
                            </div> 
                            <span> turkish-lira-minus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-turkish-lira-plus"></i>
                            </div> 
                            <span> turkish-lira-plus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-turkish-lira-true"></i>
                            </div> 
                            <span> turkish-lira-true </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-turkish-lira"></i>
                            </div> 
                            <span> turkish-lira </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-won-false"></i>
                            </div> 
                            <span> won-false </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-won-minus"></i>
                            </div> 
                            <span> won-minus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-won-plus"></i>
                            </div> 
                            <span> won-plus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-won-true"></i>
                            </div> 
                            <span> won-true </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-won"></i>
                            </div> 
                            <span> won </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-yen-false"></i>
                            </div> 
                            <span> yen-false </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-yen-minus"></i>
                            </div> 
                            <span> yen-minus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-yen-plus"></i>
                            </div> 
                            <span> yen-plus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-yen-true"></i>
                            </div> 
                            <span> yen-true </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-yen"></i>
                            </div> 
                            <span> yen </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-android-nexus"></i>
                            </div> 
                            <span> android-nexus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-android-tablet"></i>
                            </div> 
                            <span> android-tablet </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-apple-watch"></i>
                            </div> 
                            <span> apple-watch </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-drawing-tablet"></i>
                            </div> 
                            <span> drawing-tablet </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-earphone"></i>
                            </div> 
                            <span> earphone </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-flash-drive"></i>
                            </div> 
                            <span> flash-drive </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-game-console"></i>
                            </div> 
                            <span> game-console </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-game-controller"></i>
                            </div> 
                            <span> game-controller </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-game-pad"></i>
                            </div> 
                            <span> game-pad </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-game"></i>
                            </div> 
                            <span> game </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-headphone-alt-1"></i>
                            </div> 
                            <span> headphone-alt-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-headphone-alt-2"></i>
                            </div> 
                            <span> headphone-alt-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-headphone-alt-3"></i>
                            </div> 
                            <span> headphone-alt-3 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-headphone-alt"></i>
                            </div> 
                            <span> headphone-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-headphone"></i>
                            </div> 
                            <span> headphone </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-htc-one"></i>
                            </div> 
                            <span> htc-one </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-imac"></i>
                            </div> 
                            <span> imac </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ipad"></i>
                            </div> 
                            <span> ipad </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-iphone"></i>
                            </div> 
                            <span> iphone </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ipod-nano"></i>
                            </div> 
                            <span> ipod-nano </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ipod-touch"></i>
                            </div> 
                            <span> ipod-touch </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-keyboard-alt"></i>
                            </div> 
                            <span> keyboard-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-keyboard-wireless"></i>
                            </div> 
                            <span> keyboard-wireless </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-keyboard"></i>
                            </div> 
                            <span> keyboard </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-laptop-alt"></i>
                            </div> 
                            <span> laptop-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-laptop"></i>
                            </div> 
                            <span> laptop </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-macbook"></i>
                            </div> 
                            <span> macbook </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-magic-mouse"></i>
                            </div> 
                            <span> magic-mouse </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-micro-chip"></i>
                            </div> 
                            <span> micro-chip </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-microphone-alt"></i>
                            </div> 
                            <span> microphone-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-microphone"></i>
                            </div> 
                            <span> microphone </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-monitor"></i>
                            </div> 
                            <span> monitor </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-mouse"></i>
                            </div> 
                            <span> mouse </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-mp3-player"></i>
                            </div> 
                            <span> mp3-player </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-nintendo"></i>
                            </div> 
                            <span> nintendo </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-playstation-alt"></i>
                            </div> 
                            <span> playstation-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-psvita"></i>
                            </div> 
                            <span> psvita </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-radio-mic"></i>
                            </div> 
                            <span> radio-mic </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-radio"></i>
                            </div> 
                            <span> radio </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-refrigerator"></i>
                            </div> 
                            <span> refrigerator </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-samsung-galaxy"></i>
                            </div> 
                            <span> samsung-galaxy </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-surface-tablet"></i>
                            </div> 
                            <span> surface-tablet </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-head-phone"></i>
                            </div> 
                            <span> ui-head-phone </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-keyboard"></i>
                            </div> 
                            <span> ui-keyboard </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-washing-machine"></i>
                            </div> 
                            <span> washing-machine </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-wifi-router"></i>
                            </div> 
                            <span> wifi-router </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-wii-u"></i>
                            </div> 
                            <span> wii-u </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-windows-lumia"></i>
                            </div> 
                            <span> windows-lumia </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-wireless-mouse"></i>
                            </div> 
                            <span> wireless-mouse </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-xbox-360"></i>
                            </div> 
                            <span> xbox-360 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-arrow-down"></i>
                            </div> 
                            <span> arrow-down </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-arrow-left"></i>
                            </div> 
                            <span> arrow-left </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-arrow-right"></i>
                            </div> 
                            <span> arrow-right </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-arrow-up"></i>
                            </div> 
                            <span> arrow-up </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-block-down"></i>
                            </div> 
                            <span> block-down </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-block-left"></i>
                            </div> 
                            <span> block-left </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-block-right"></i>
                            </div> 
                            <span> block-right </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-block-up"></i>
                            </div> 
                            <span> block-up </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bubble-down"></i>
                            </div> 
                            <span> bubble-down </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bubble-left"></i>
                            </div> 
                            <span> bubble-left </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bubble-right"></i>
                            </div> 
                            <span> bubble-right </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bubble-up"></i>
                            </div> 
                            <span> bubble-up </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-caret-down"></i>
                            </div> 
                            <span> caret-down </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-caret-left"></i>
                            </div> 
                            <span> caret-left </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-caret-right"></i>
                            </div> 
                            <span> caret-right </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-caret-up"></i>
                            </div> 
                            <span> caret-up </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-circled-down"></i>
                            </div> 
                            <span> circled-down </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-circled-left"></i>
                            </div> 
                            <span> circled-left </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-circled-right"></i>
                            </div> 
                            <span> circled-right </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-circled-up"></i>
                            </div> 
                            <span> circled-up </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-collapse"></i>
                            </div> 
                            <span> collapse </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cursor-drag"></i>
                            </div> 
                            <span> cursor-drag </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-curved-double-left"></i>
                            </div> 
                            <span> curved-double-left </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-curved-double-right"></i>
                            </div> 
                            <span> curved-double-right </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-curved-down"></i>
                            </div> 
                            <span> curved-down </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-curved-left"></i>
                            </div> 
                            <span> curved-left </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-curved-right"></i>
                            </div> 
                            <span> curved-right </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-curved-up"></i>
                            </div> 
                            <span> curved-up </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dotted-down"></i>
                            </div> 
                            <span> dotted-down </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dotted-left"></i>
                            </div> 
                            <span> dotted-left </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dotted-right"></i>
                            </div> 
                            <span> dotted-right </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dotted-up"></i>
                            </div> 
                            <span> dotted-up </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-double-left"></i>
                            </div> 
                            <span> double-left </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-double-right"></i>
                            </div> 
                            <span> double-right </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-expand-alt"></i>
                            </div> 
                            <span> expand-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hand-down"></i>
                            </div> 
                            <span> hand-down </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hand-drag"></i>
                            </div> 
                            <span> hand-drag </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hand-drag1"></i>
                            </div> 
                            <span> hand-drag1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hand-drag2"></i>
                            </div> 
                            <span> hand-drag2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hand-drawn-alt-down"></i>
                            </div> 
                            <span> hand-drawn-alt-down </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hand-drawn-alt-left"></i>
                            </div> 
                            <span> hand-drawn-alt-left </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hand-drawn-alt-right"></i>
                            </div> 
                            <span> hand-drawn-alt-right </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hand-drawn-alt-up"></i>
                            </div> 
                            <span> hand-drawn-alt-up </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hand-drawn-down"></i>
                            </div> 
                            <span> hand-drawn-down </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hand-drawn-left"></i>
                            </div> 
                            <span> hand-drawn-left </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hand-drawn-right"></i>
                            </div> 
                            <span> hand-drawn-right </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hand-drawn-up"></i>
                            </div> 
                            <span> hand-drawn-up </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hand-grippers"></i>
                            </div> 
                            <span> hand-grippers </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hand-left"></i>
                            </div> 
                            <span> hand-left </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hand-right"></i>
                            </div> 
                            <span> hand-right </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hand-up"></i>
                            </div> 
                            <span> hand-up </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-line-block-down"></i>
                            </div> 
                            <span> line-block-down </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-line-block-left"></i>
                            </div> 
                            <span> line-block-left </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-line-block-right"></i>
                            </div> 
                            <span> line-block-right </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-line-block-up"></i>
                            </div> 
                            <span> line-block-up </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-long-arrow-down"></i>
                            </div> 
                            <span> long-arrow-down </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-long-arrow-left"></i>
                            </div> 
                            <span> long-arrow-left </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-long-arrow-right"></i>
                            </div> 
                            <span> long-arrow-right </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-long-arrow-up"></i>
                            </div> 
                            <span> long-arrow-up </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rounded-collapse"></i>
                            </div> 
                            <span> rounded-collapse </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rounded-double-left"></i>
                            </div> 
                            <span> rounded-double-left </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rounded-double-right"></i>
                            </div> 
                            <span> rounded-double-right </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rounded-down"></i>
                            </div> 
                            <span> rounded-down </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rounded-expand"></i>
                            </div> 
                            <span> rounded-expand </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rounded-left-down"></i>
                            </div> 
                            <span> rounded-left-down </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rounded-left-up"></i>
                            </div> 
                            <span> rounded-left-up </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rounded-left"></i>
                            </div> 
                            <span> rounded-left </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rounded-right-down"></i>
                            </div> 
                            <span> rounded-right-down </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rounded-right-up"></i>
                            </div> 
                            <span> rounded-right-up </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rounded-right"></i>
                            </div> 
                            <span> rounded-right </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rounded-up"></i>
                            </div> 
                            <span> rounded-up </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-scroll-bubble-down"></i>
                            </div> 
                            <span> scroll-bubble-down </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-scroll-bubble-left"></i>
                            </div> 
                            <span> scroll-bubble-left </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-scroll-bubble-right"></i>
                            </div> 
                            <span> scroll-bubble-right </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-scroll-bubble-up"></i>
                            </div> 
                            <span> scroll-bubble-up </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-scroll-double-down"></i>
                            </div> 
                            <span> scroll-double-down </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-scroll-double-left"></i>
                            </div> 
                            <span> scroll-double-left </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-scroll-double-right"></i>
                            </div> 
                            <span> scroll-double-right </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-scroll-double-up"></i>
                            </div> 
                            <span> scroll-double-up </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-scroll-down"></i>
                            </div> 
                            <span> scroll-down </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-scroll-left"></i>
                            </div> 
                            <span> scroll-left </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-scroll-long-down"></i>
                            </div> 
                            <span> scroll-long-down </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-scroll-long-left"></i>
                            </div> 
                            <span> scroll-long-left </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-scroll-long-right"></i>
                            </div> 
                            <span> scroll-long-right </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-scroll-long-up"></i>
                            </div> 
                            <span> scroll-long-up </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-scroll-right"></i>
                            </div> 
                            <span> scroll-right </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-scroll-up"></i>
                            </div> 
                            <span> scroll-up </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-simple-down"></i>
                            </div> 
                            <span> simple-down </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-simple-left-down"></i>
                            </div> 
                            <span> simple-left-down </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-simple-left-up"></i>
                            </div> 
                            <span> simple-left-up </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-simple-left"></i>
                            </div> 
                            <span> simple-left </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-simple-right-down"></i>
                            </div> 
                            <span> simple-right-down </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-simple-right-up"></i>
                            </div> 
                            <span> simple-right-up </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-simple-right"></i>
                            </div> 
                            <span> simple-right </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-simple-up"></i>
                            </div> 
                            <span> simple-up </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-square-down"></i>
                            </div> 
                            <span> square-down </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-square-left"></i>
                            </div> 
                            <span> square-left </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-square-right"></i>
                            </div> 
                            <span> square-right </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-square-up"></i>
                            </div> 
                            <span> square-up </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-stylish-down"></i>
                            </div> 
                            <span> stylish-down </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-stylish-left"></i>
                            </div> 
                            <span> stylish-left </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-stylish-right"></i>
                            </div> 
                            <span> stylish-right </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-stylish-up"></i>
                            </div> 
                            <span> stylish-up </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-swoosh-down"></i>
                            </div> 
                            <span> swoosh-down </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-swoosh-left"></i>
                            </div> 
                            <span> swoosh-left </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-swoosh-right"></i>
                            </div> 
                            <span> swoosh-right </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-swoosh-up"></i>
                            </div> 
                            <span> swoosh-up </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-thin-double-left"></i>
                            </div> 
                            <span> thin-double-left </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-thin-double-right"></i>
                            </div> 
                            <span> thin-double-right </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-thin-down"></i>
                            </div> 
                            <span> thin-down </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-thin-left"></i>
                            </div> 
                            <span> thin-left </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-thin-right"></i>
                            </div> 
                            <span> thin-right </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-thin-up"></i>
                            </div> 
                            <span> thin-up </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-abc"></i>
                            </div> 
                            <span> abc </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-atom"></i>
                            </div> 
                            <span> atom </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-award"></i>
                            </div> 
                            <span> award </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bell-alt"></i>
                            </div> 
                            <span> bell-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-black-board"></i>
                            </div> 
                            <span> black-board </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-book-alt"></i>
                            </div> 
                            <span> book-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-book"></i>
                            </div> 
                            <span> book </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brainstorming"></i>
                            </div> 
                            <span> brainstorming </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-certificate-alt-1"></i>
                            </div> 
                            <span> certificate-alt-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-certificate-alt-2"></i>
                            </div> 
                            <span> certificate-alt-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-certificate"></i>
                            </div> 
                            <span> certificate </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-education"></i>
                            </div> 
                            <span> education </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-electron"></i>
                            </div> 
                            <span> electron </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-fountain-pen"></i>
                            </div> 
                            <span> fountain-pen </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-globe-alt"></i>
                            </div> 
                            <span> globe-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-graduate-alt"></i>
                            </div> 
                            <span> graduate-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-graduate"></i>
                            </div> 
                            <span> graduate </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-group-students"></i>
                            </div> 
                            <span> group-students </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hat-alt"></i>
                            </div> 
                            <span> hat-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hat"></i>
                            </div> 
                            <span> hat </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-instrument"></i>
                            </div> 
                            <span> instrument </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-lamp-light"></i>
                            </div> 
                            <span> lamp-light </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-medal"></i>
                            </div> 
                            <span> medal </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-microscope-alt"></i>
                            </div> 
                            <span> microscope-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-microscope"></i>
                            </div> 
                            <span> microscope </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-paper"></i>
                            </div> 
                            <span> paper </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pen-alt-4"></i>
                            </div> 
                            <span> pen-alt-4 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pen-nib"></i>
                            </div> 
                            <span> pen-nib </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pencil-alt-5"></i>
                            </div> 
                            <span> pencil-alt-5 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-quill-pen"></i>
                            </div> 
                            <span> quill-pen </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-read-book-alt"></i>
                            </div> 
                            <span> read-book-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-read-book"></i>
                            </div> 
                            <span> read-book </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-school-bag"></i>
                            </div> 
                            <span> school-bag </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-school-bus"></i>
                            </div> 
                            <span> school-bus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-student-alt"></i>
                            </div> 
                            <span> student-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-student"></i>
                            </div> 
                            <span> student </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-teacher"></i>
                            </div> 
                            <span> teacher </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-test-bulb"></i>
                            </div> 
                            <span> test-bulb </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-test-tube-alt"></i>
                            </div> 
                            <span> test-tube-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-university"></i>
                            </div> 
                            <span> university </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-angry"></i>
                            </div> 
                            <span> angry </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-astonished"></i>
                            </div> 
                            <span> astonished </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-confounded"></i>
                            </div> 
                            <span> confounded </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-confused"></i>
                            </div> 
                            <span> confused </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-crying"></i>
                            </div> 
                            <span> crying </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dizzy"></i>
                            </div> 
                            <span> dizzy </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-expressionless"></i>
                            </div> 
                            <span> expressionless </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-heart-eyes"></i>
                            </div> 
                            <span> heart-eyes </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-laughing"></i>
                            </div> 
                            <span> laughing </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-nerd-smile"></i>
                            </div> 
                            <span> nerd-smile </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-open-mouth"></i>
                            </div> 
                            <span> open-mouth </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rage"></i>
                            </div> 
                            <span> rage </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rolling-eyes"></i>
                            </div> 
                            <span> rolling-eyes </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-sad"></i>
                            </div> 
                            <span> sad </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-simple-smile"></i>
                            </div> 
                            <span> simple-smile </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-slightly-smile"></i>
                            </div> 
                            <span> slightly-smile </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-smirk"></i>
                            </div> 
                            <span> smirk </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-stuck-out-tongue"></i>
                            </div> 
                            <span> stuck-out-tongue </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-wink-smile"></i>
                            </div> 
                            <span> wink-smile </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-worried"></i>
                            </div> 
                            <span> worried </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-alt"></i>
                            </div> 
                            <span> file-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-audio"></i>
                            </div> 
                            <span> file-audio </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-avi-mp4"></i>
                            </div> 
                            <span> file-avi-mp4 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-bmp"></i>
                            </div> 
                            <span> file-bmp </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-code"></i>
                            </div> 
                            <span> file-code </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-css"></i>
                            </div> 
                            <span> file-css </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-document"></i>
                            </div> 
                            <span> file-document </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-eps"></i>
                            </div> 
                            <span> file-eps </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-excel"></i>
                            </div> 
                            <span> file-excel </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-exe"></i>
                            </div> 
                            <span> file-exe </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-file"></i>
                            </div> 
                            <span> file-file </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-flv"></i>
                            </div> 
                            <span> file-flv </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-gif"></i>
                            </div> 
                            <span> file-gif </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-html5"></i>
                            </div> 
                            <span> file-html5 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-image"></i>
                            </div> 
                            <span> file-image </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-iso"></i>
                            </div> 
                            <span> file-iso </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-java"></i>
                            </div> 
                            <span> file-java </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-javascript"></i>
                            </div> 
                            <span> file-javascript </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-jpg"></i>
                            </div> 
                            <span> file-jpg </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-midi"></i>
                            </div> 
                            <span> file-midi </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-mov"></i>
                            </div> 
                            <span> file-mov </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-mp3"></i>
                            </div> 
                            <span> file-mp3 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-pdf"></i>
                            </div> 
                            <span> file-pdf </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-php"></i>
                            </div> 
                            <span> file-php </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-png"></i>
                            </div> 
                            <span> file-png </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-powerpoint"></i>
                            </div> 
                            <span> file-powerpoint </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-presentation"></i>
                            </div> 
                            <span> file-presentation </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-psb"></i>
                            </div> 
                            <span> file-psb </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-psd"></i>
                            </div> 
                            <span> file-psd </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-python"></i>
                            </div> 
                            <span> file-python </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-ruby"></i>
                            </div> 
                            <span> file-ruby </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-spreadsheet"></i>
                            </div> 
                            <span> file-spreadsheet </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-sql"></i>
                            </div> 
                            <span> file-sql </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-svg"></i>
                            </div> 
                            <span> file-svg </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-text"></i>
                            </div> 
                            <span> file-text </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-tiff"></i>
                            </div> 
                            <span> file-tiff </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-video"></i>
                            </div> 
                            <span> file-video </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-wave"></i>
                            </div> 
                            <span> file-wave </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-wmv"></i>
                            </div> 
                            <span> file-wmv </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-word"></i>
                            </div> 
                            <span> file-word </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-zip"></i>
                            </div> 
                            <span> file-zip </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cycling-alt"></i>
                            </div> 
                            <span> cycling-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cycling"></i>
                            </div> 
                            <span> cycling </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dumbbell"></i>
                            </div> 
                            <span> dumbbell </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dumbbells"></i>
                            </div> 
                            <span> dumbbells </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-gym-alt-1"></i>
                            </div> 
                            <span> gym-alt-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-gym-alt-2"></i>
                            </div> 
                            <span> gym-alt-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-gym-alt-3"></i>
                            </div> 
                            <span> gym-alt-3 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-gym"></i>
                            </div> 
                            <span> gym </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-muscle-weight"></i>
                            </div> 
                            <span> muscle-weight </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-muscle"></i>
                            </div> 
                            <span> muscle </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-apple"></i>
                            </div> 
                            <span> apple </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-arabian-coffee"></i>
                            </div> 
                            <span> arabian-coffee </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-artichoke"></i>
                            </div> 
                            <span> artichoke </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-asparagus"></i>
                            </div> 
                            <span> asparagus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-avocado"></i>
                            </div> 
                            <span> avocado </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-baby-food"></i>
                            </div> 
                            <span> baby-food </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-banana"></i>
                            </div> 
                            <span> banana </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bbq"></i>
                            </div> 
                            <span> bbq </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-beans"></i>
                            </div> 
                            <span> beans </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-beer"></i>
                            </div> 
                            <span> beer </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bell-pepper-capsicum"></i>
                            </div> 
                            <span> bell-pepper-capsicum </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-birthday-cake"></i>
                            </div> 
                            <span> birthday-cake </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bread"></i>
                            </div> 
                            <span> bread </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-broccoli"></i>
                            </div> 
                            <span> broccoli </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-burger"></i>
                            </div> 
                            <span> burger </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cabbage"></i>
                            </div> 
                            <span> cabbage </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-carrot"></i>
                            </div> 
                            <span> carrot </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cauli-flower"></i>
                            </div> 
                            <span> cauli-flower </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cheese"></i>
                            </div> 
                            <span> cheese </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-chef"></i>
                            </div> 
                            <span> chef </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cherry"></i>
                            </div> 
                            <span> cherry </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-chicken-fry"></i>
                            </div> 
                            <span> chicken-fry </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-chicken"></i>
                            </div> 
                            <span> chicken </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cocktail"></i>
                            </div> 
                            <span> cocktail </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-coconut-water"></i>
                            </div> 
                            <span> coconut-water </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-coconut"></i>
                            </div> 
                            <span> coconut </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-coffee-alt"></i>
                            </div> 
                            <span> coffee-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-coffee-cup"></i>
                            </div> 
                            <span> coffee-cup </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-coffee-mug"></i>
                            </div> 
                            <span> coffee-mug </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-coffee-pot"></i>
                            </div> 
                            <span> coffee-pot </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cola"></i>
                            </div> 
                            <span> cola </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-corn"></i>
                            </div> 
                            <span> corn </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-croissant"></i>
                            </div> 
                            <span> croissant </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-crop-plant"></i>
                            </div> 
                            <span> crop-plant </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cucumber"></i>
                            </div> 
                            <span> cucumber </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-culinary"></i>
                            </div> 
                            <span> culinary </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cup-cake"></i>
                            </div> 
                            <span> cup-cake </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dining-table"></i>
                            </div> 
                            <span> dining-table </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-donut"></i>
                            </div> 
                            <span> donut </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-egg-plant"></i>
                            </div> 
                            <span> egg-plant </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-egg-poached"></i>
                            </div> 
                            <span> egg-poached </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-farmer-alt"></i>
                            </div> 
                            <span> farmer-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-farmer"></i>
                            </div> 
                            <span> farmer </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-fast-food"></i>
                            </div> 
                            <span> fast-food </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-food-basket"></i>
                            </div> 
                            <span> food-basket </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-food-cart"></i>
                            </div> 
                            <span> food-cart </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-fork-and-knife"></i>
                            </div> 
                            <span> fork-and-knife </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-french-fries"></i>
                            </div> 
                            <span> french-fries </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-fruits"></i>
                            </div> 
                            <span> fruits </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-grapes"></i>
                            </div> 
                            <span> grapes </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-honey"></i>
                            </div> 
                            <span> honey </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hot-dog"></i>
                            </div> 
                            <span> hot-dog </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ice-cream-alt"></i>
                            </div> 
                            <span> ice-cream-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ice-cream"></i>
                            </div> 
                            <span> ice-cream </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-juice"></i>
                            </div> 
                            <span> juice </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ketchup"></i>
                            </div> 
                            <span> ketchup </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-kiwi"></i>
                            </div> 
                            <span> kiwi </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-layered-cake"></i>
                            </div> 
                            <span> layered-cake </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-lemon-alt"></i>
                            </div> 
                            <span> lemon-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-lemon"></i>
                            </div> 
                            <span> lemon </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-lobster"></i>
                            </div> 
                            <span> lobster </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-mango"></i>
                            </div> 
                            <span> mango </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-milk"></i>
                            </div> 
                            <span> milk </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-mushroom"></i>
                            </div> 
                            <span> mushroom </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-noodles"></i>
                            </div> 
                            <span> noodles </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-onion"></i>
                            </div> 
                            <span> onion </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-orange"></i>
                            </div> 
                            <span> orange </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pear"></i>
                            </div> 
                            <span> pear </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-peas"></i>
                            </div> 
                            <span> peas </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pepper"></i>
                            </div> 
                            <span> pepper </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pie-alt"></i>
                            </div> 
                            <span> pie-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pie"></i>
                            </div> 
                            <span> pie </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pineapple"></i>
                            </div> 
                            <span> pineapple </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pizza-slice"></i>
                            </div> 
                            <span> pizza-slice </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pizza"></i>
                            </div> 
                            <span> pizza </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-plant"></i>
                            </div> 
                            <span> plant </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-popcorn"></i>
                            </div> 
                            <span> popcorn </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-potato"></i>
                            </div> 
                            <span> potato </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pumpkin"></i>
                            </div> 
                            <span> pumpkin </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-raddish"></i>
                            </div> 
                            <span> raddish </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-restaurant-menu"></i>
                            </div> 
                            <span> restaurant-menu </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-restaurant"></i>
                            </div> 
                            <span> restaurant </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-salt-and-pepper"></i>
                            </div> 
                            <span> salt-and-pepper </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-sandwich"></i>
                            </div> 
                            <span> sandwich </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-sausage"></i>
                            </div> 
                            <span> sausage </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-soft-drinks"></i>
                            </div> 
                            <span> soft-drinks </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-soup-bowl"></i>
                            </div> 
                            <span> soup-bowl </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-spoon-and-fork"></i>
                            </div> 
                            <span> spoon-and-fork </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-steak"></i>
                            </div> 
                            <span> steak </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-strawberry"></i>
                            </div> 
                            <span> strawberry </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-sub-sandwich"></i>
                            </div> 
                            <span> sub-sandwich </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-sushi"></i>
                            </div> 
                            <span> sushi </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-taco"></i>
                            </div> 
                            <span> taco </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-tea-pot"></i>
                            </div> 
                            <span> tea-pot </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-tea"></i>
                            </div> 
                            <span> tea </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-tomato"></i>
                            </div> 
                            <span> tomato </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-watermelon"></i>
                            </div> 
                            <span> watermelon </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-wheat"></i>
                            </div> 
                            <span> wheat </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-baby-backpack"></i>
                            </div> 
                            <span> baby-backpack </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-baby-cloth"></i>
                            </div> 
                            <span> baby-cloth </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-baby-milk-bottle"></i>
                            </div> 
                            <span> baby-milk-bottle </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-baby-trolley"></i>
                            </div> 
                            <span> baby-trolley </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-baby"></i>
                            </div> 
                            <span> baby </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-candy"></i>
                            </div> 
                            <span> candy </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-holding-hands"></i>
                            </div> 
                            <span> holding-hands </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-infant-nipple"></i>
                            </div> 
                            <span> infant-nipple </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-kids-scooter"></i>
                            </div> 
                            <span> kids-scooter </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-safety-pin"></i>
                            </div> 
                            <span> safety-pin </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-teddy-bear"></i>
                            </div> 
                            <span> teddy-bear </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-toy-ball"></i>
                            </div> 
                            <span> toy-ball </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-toy-cat"></i>
                            </div> 
                            <span> toy-cat </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-toy-duck"></i>
                            </div> 
                            <span> toy-duck </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-toy-elephant"></i>
                            </div> 
                            <span> toy-elephant </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-toy-hand"></i>
                            </div> 
                            <span> toy-hand </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-toy-horse"></i>
                            </div> 
                            <span> toy-horse </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-toy-lattu"></i>
                            </div> 
                            <span> toy-lattu </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-toy-train"></i>
                            </div> 
                            <span> toy-train </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-burglar"></i>
                            </div> 
                            <span> burglar </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cannon-firing"></i>
                            </div> 
                            <span> cannon-firing </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cc-camera"></i>
                            </div> 
                            <span> cc-camera </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cop-badge"></i>
                            </div> 
                            <span> cop-badge </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cop"></i>
                            </div> 
                            <span> cop </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-court-hammer"></i>
                            </div> 
                            <span> court-hammer </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-court"></i>
                            </div> 
                            <span> court </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-finger-print"></i>
                            </div> 
                            <span> finger-print </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-gavel"></i>
                            </div> 
                            <span> gavel </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-handcuff-alt"></i>
                            </div> 
                            <span> handcuff-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-handcuff"></i>
                            </div> 
                            <span> handcuff </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-investigation"></i>
                            </div> 
                            <span> investigation </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-investigator"></i>
                            </div> 
                            <span> investigator </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-jail"></i>
                            </div> 
                            <span> jail </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-judge"></i>
                            </div> 
                            <span> judge </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-law-alt-1"></i>
                            </div> 
                            <span> law-alt-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-law-alt-2"></i>
                            </div> 
                            <span> law-alt-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-law-alt-3"></i>
                            </div> 
                            <span> law-alt-3 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-law-book"></i>
                            </div> 
                            <span> law-book </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-law-document"></i>
                            </div> 
                            <span> law-document </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-law-order"></i>
                            </div> 
                            <span> law-order </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-law-protect"></i>
                            </div> 
                            <span> law-protect </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-law-scales"></i>
                            </div> 
                            <span> law-scales </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-law"></i>
                            </div> 
                            <span> law </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-lawyer-alt-1"></i>
                            </div> 
                            <span> lawyer-alt-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-lawyer-alt-2"></i>
                            </div> 
                            <span> lawyer-alt-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-lawyer"></i>
                            </div> 
                            <span> lawyer </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-legal"></i>
                            </div> 
                            <span> legal </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pistol"></i>
                            </div> 
                            <span> pistol </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-police-badge"></i>
                            </div> 
                            <span> police-badge </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-police-cap"></i>
                            </div> 
                            <span> police-cap </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-police-car-alt-1"></i>
                            </div> 
                            <span> police-car-alt-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-police-car-alt-2"></i>
                            </div> 
                            <span> police-car-alt-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-police-car"></i>
                            </div> 
                            <span> police-car </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-police-hat"></i>
                            </div> 
                            <span> police-hat </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-police-van"></i>
                            </div> 
                            <span> police-van </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-police"></i>
                            </div> 
                            <span> police </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-thief-alt"></i>
                            </div> 
                            <span> thief-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-thief"></i>
                            </div> 
                            <span> thief </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-abacus-alt"></i>
                            </div> 
                            <span> abacus-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-abacus"></i>
                            </div> 
                            <span> abacus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-angle-180"></i>
                            </div> 
                            <span> angle-180 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-angle-45"></i>
                            </div> 
                            <span> angle-45 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-angle-90"></i>
                            </div> 
                            <span> angle-90 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-angle"></i>
                            </div> 
                            <span> angle </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-calculator-alt-1"></i>
                            </div> 
                            <span> calculator-alt-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-calculator-alt-2"></i>
                            </div> 
                            <span> calculator-alt-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-calculator"></i>
                            </div> 
                            <span> calculator </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-circle-ruler-alt"></i>
                            </div> 
                            <span> circle-ruler-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-circle-ruler"></i>
                            </div> 
                            <span> circle-ruler </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-compass-alt-1"></i>
                            </div> 
                            <span> compass-alt-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-compass-alt-2"></i>
                            </div> 
                            <span> compass-alt-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-compass-alt-3"></i>
                            </div> 
                            <span> compass-alt-3 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-compass-alt-4"></i>
                            </div> 
                            <span> compass-alt-4 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-golden-ratio"></i>
                            </div> 
                            <span> golden-ratio </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-marker-alt-1"></i>
                            </div> 
                            <span> marker-alt-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-marker-alt-2"></i>
                            </div> 
                            <span> marker-alt-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-marker-alt-3"></i>
                            </div> 
                            <span> marker-alt-3 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-marker"></i>
                            </div> 
                            <span> marker </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-math"></i>
                            </div> 
                            <span> math </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-mathematical-alt-1"></i>
                            </div> 
                            <span> mathematical-alt-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-mathematical-alt-2"></i>
                            </div> 
                            <span> mathematical-alt-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-mathematical"></i>
                            </div> 
                            <span> mathematical </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pen-alt-1"></i>
                            </div> 
                            <span> pen-alt-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pen-alt-2"></i>
                            </div> 
                            <span> pen-alt-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pen-alt-3"></i>
                            </div> 
                            <span> pen-alt-3 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pen-holder-alt-1"></i>
                            </div> 
                            <span> pen-holder-alt-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pen-holder"></i>
                            </div> 
                            <span> pen-holder </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pen"></i>
                            </div> 
                            <span> pen </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pencil-alt-1"></i>
                            </div> 
                            <span> pencil-alt-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pencil-alt-2"></i>
                            </div> 
                            <span> pencil-alt-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pencil-alt-3"></i>
                            </div> 
                            <span> pencil-alt-3 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pencil-alt-4"></i>
                            </div> 
                            <span> pencil-alt-4 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pencil"></i>
                            </div> 
                            <span> pencil </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ruler-alt-1"></i>
                            </div> 
                            <span> ruler-alt-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ruler-alt-2"></i>
                            </div> 
                            <span> ruler-alt-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ruler-compass-alt"></i>
                            </div> 
                            <span> ruler-compass-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ruler-compass"></i>
                            </div> 
                            <span> ruler-compass </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ruler-pencil-alt-1"></i>
                            </div> 
                            <span> ruler-pencil-alt-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ruler-pencil-alt-2"></i>
                            </div> 
                            <span> ruler-pencil-alt-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ruler-pencil"></i>
                            </div> 
                            <span> ruler-pencil </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ruler"></i>
                            </div> 
                            <span> ruler </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rulers-alt"></i>
                            </div> 
                            <span> rulers-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rulers"></i>
                            </div> 
                            <span> rulers </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-square-root"></i>
                            </div> 
                            <span> square-root </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-calculator"></i>
                            </div> 
                            <span> ui-calculator </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-aids"></i>
                            </div> 
                            <span> aids </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ambulance-crescent"></i>
                            </div> 
                            <span> ambulance-crescent </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ambulance-cross"></i>
                            </div> 
                            <span> ambulance-cross </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ambulance"></i>
                            </div> 
                            <span> ambulance </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-autism"></i>
                            </div> 
                            <span> autism </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bandage"></i>
                            </div> 
                            <span> bandage </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-blind"></i>
                            </div> 
                            <span> blind </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-blood-drop"></i>
                            </div> 
                            <span> blood-drop </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-blood-test"></i>
                            </div> 
                            <span> blood-test </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-blood"></i>
                            </div> 
                            <span> blood </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brain-alt"></i>
                            </div> 
                            <span> brain-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brain"></i>
                            </div> 
                            <span> brain </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-capsule"></i>
                            </div> 
                            <span> capsule </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-crutch"></i>
                            </div> 
                            <span> crutch </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-disabled"></i>
                            </div> 
                            <span> disabled </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dna-alt-1"></i>
                            </div> 
                            <span> dna-alt-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dna-alt-2"></i>
                            </div> 
                            <span> dna-alt-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dna"></i>
                            </div> 
                            <span> dna </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-doctor-alt"></i>
                            </div> 
                            <span> doctor-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-doctor"></i>
                            </div> 
                            <span> doctor </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-drug-pack"></i>
                            </div> 
                            <span> drug-pack </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-drug"></i>
                            </div> 
                            <span> drug </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-first-aid-alt"></i>
                            </div> 
                            <span> first-aid-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-first-aid"></i>
                            </div> 
                            <span> first-aid </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-heart-beat-alt"></i>
                            </div> 
                            <span> heart-beat-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-heart-beat"></i>
                            </div> 
                            <span> heart-beat </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-heartbeat"></i>
                            </div> 
                            <span> heartbeat </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-herbal"></i>
                            </div> 
                            <span> herbal </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hospital"></i>
                            </div> 
                            <span> hospital </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-icu"></i>
                            </div> 
                            <span> icu </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-injection-syringe"></i>
                            </div> 
                            <span> injection-syringe </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-laboratory"></i>
                            </div> 
                            <span> laboratory </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-medical-sign-alt"></i>
                            </div> 
                            <span> medical-sign-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-medical-sign"></i>
                            </div> 
                            <span> medical-sign </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-nurse-alt"></i>
                            </div> 
                            <span> nurse-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-nurse"></i>
                            </div> 
                            <span> nurse </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-nursing-home"></i>
                            </div> 
                            <span> nursing-home </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-operation-theater"></i>
                            </div> 
                            <span> operation-theater </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-paralysis-disability"></i>
                            </div> 
                            <span> paralysis-disability </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-patient-bed"></i>
                            </div> 
                            <span> patient-bed </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-patient-file"></i>
                            </div> 
                            <span> patient-file </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pills"></i>
                            </div> 
                            <span> pills </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-prescription"></i>
                            </div> 
                            <span> prescription </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pulse"></i>
                            </div> 
                            <span> pulse </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-stethoscope-alt"></i>
                            </div> 
                            <span> stethoscope-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-stethoscope"></i>
                            </div> 
                            <span> stethoscope </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-stretcher"></i>
                            </div> 
                            <span> stretcher </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-surgeon-alt"></i>
                            </div> 
                            <span> surgeon-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-surgeon"></i>
                            </div> 
                            <span> surgeon </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-tablets"></i>
                            </div> 
                            <span> tablets </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-test-bottle"></i>
                            </div> 
                            <span> test-bottle </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-test-tube"></i>
                            </div> 
                            <span> test-tube </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-thermometer-alt"></i>
                            </div> 
                            <span> thermometer-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-thermometer"></i>
                            </div> 
                            <span> thermometer </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-tooth"></i>
                            </div> 
                            <span> tooth </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-xray"></i>
                            </div> 
                            <span> xray </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-add"></i>
                            </div> 
                            <span> ui-add </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-alarm"></i>
                            </div> 
                            <span> ui-alarm </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-battery"></i>
                            </div> 
                            <span> ui-battery </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-block"></i>
                            </div> 
                            <span> ui-block </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-bluetooth"></i>
                            </div> 
                            <span> ui-bluetooth </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-brightness"></i>
                            </div> 
                            <span> ui-brightness </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-browser"></i>
                            </div> 
                            <span> ui-browser </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-calendar"></i>
                            </div> 
                            <span> ui-calendar </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-call"></i>
                            </div> 
                            <span> ui-call </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-camera"></i>
                            </div> 
                            <span> ui-camera </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-cart"></i>
                            </div> 
                            <span> ui-cart </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-cell-phone"></i>
                            </div> 
                            <span> ui-cell-phone </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-chat"></i>
                            </div> 
                            <span> ui-chat </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-check"></i>
                            </div> 
                            <span> ui-check </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-clip-board"></i>
                            </div> 
                            <span> ui-clip-board </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-clip"></i>
                            </div> 
                            <span> ui-clip </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-clock"></i>
                            </div> 
                            <span> ui-clock </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-close"></i>
                            </div> 
                            <span> ui-close </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-contact-list"></i>
                            </div> 
                            <span> ui-contact-list </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-copy"></i>
                            </div> 
                            <span> ui-copy </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-cut"></i>
                            </div> 
                            <span> ui-cut </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-delete"></i>
                            </div> 
                            <span> ui-delete </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-dial-phone"></i>
                            </div> 
                            <span> ui-dial-phone </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-edit"></i>
                            </div> 
                            <span> ui-edit </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-email"></i>
                            </div> 
                            <span> ui-email </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-file"></i>
                            </div> 
                            <span> ui-file </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-fire-wall"></i>
                            </div> 
                            <span> ui-fire-wall </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-flash-light"></i>
                            </div> 
                            <span> ui-flash-light </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-flight"></i>
                            </div> 
                            <span> ui-flight </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-folder"></i>
                            </div> 
                            <span> ui-folder </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-game"></i>
                            </div> 
                            <span> ui-game </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-handicapped"></i>
                            </div> 
                            <span> ui-handicapped </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-home"></i>
                            </div> 
                            <span> ui-home </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-image"></i>
                            </div> 
                            <span> ui-image </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-laoding"></i>
                            </div> 
                            <span> ui-laoding </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-lock"></i>
                            </div> 
                            <span> ui-lock </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-love-add"></i>
                            </div> 
                            <span> ui-love-add </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-love-broken"></i>
                            </div> 
                            <span> ui-love-broken </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-love-remove"></i>
                            </div> 
                            <span> ui-love-remove </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-love"></i>
                            </div> 
                            <span> ui-love </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-map"></i>
                            </div> 
                            <span> ui-map </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-message"></i>
                            </div> 
                            <span> ui-message </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-messaging"></i>
                            </div> 
                            <span> ui-messaging </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-movie"></i>
                            </div> 
                            <span> ui-movie </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-music-player"></i>
                            </div> 
                            <span> ui-music-player </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-music"></i>
                            </div> 
                            <span> ui-music </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-mute"></i>
                            </div> 
                            <span> ui-mute </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-network"></i>
                            </div> 
                            <span> ui-network </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-next"></i>
                            </div> 
                            <span> ui-next </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-note"></i>
                            </div> 
                            <span> ui-note </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-office"></i>
                            </div> 
                            <span> ui-office </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-password"></i>
                            </div> 
                            <span> ui-password </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-pause"></i>
                            </div> 
                            <span> ui-pause </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-play-stop"></i>
                            </div> 
                            <span> ui-play-stop </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-play"></i>
                            </div> 
                            <span> ui-play </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-pointer"></i>
                            </div> 
                            <span> ui-pointer </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-power"></i>
                            </div> 
                            <span> ui-power </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-press"></i>
                            </div> 
                            <span> ui-press </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-previous"></i>
                            </div> 
                            <span> ui-previous </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-rate-add"></i>
                            </div> 
                            <span> ui-rate-add </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-rate-blank"></i>
                            </div> 
                            <span> ui-rate-blank </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-rate-remove"></i>
                            </div> 
                            <span> ui-rate-remove </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-rating"></i>
                            </div> 
                            <span> ui-rating </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-record"></i>
                            </div> 
                            <span> ui-record </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-remove"></i>
                            </div> 
                            <span> ui-remove </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-reply"></i>
                            </div> 
                            <span> ui-reply </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-rotation"></i>
                            </div> 
                            <span> ui-rotation </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-rss"></i>
                            </div> 
                            <span> ui-rss </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-search"></i>
                            </div> 
                            <span> ui-search </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-settings"></i>
                            </div> 
                            <span> ui-settings </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-social-link"></i>
                            </div> 
                            <span> ui-social-link </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-tag"></i>
                            </div> 
                            <span> ui-tag </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-text-chat"></i>
                            </div> 
                            <span> ui-text-chat </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-text-loading"></i>
                            </div> 
                            <span> ui-text-loading </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-theme"></i>
                            </div> 
                            <span> ui-theme </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-timer"></i>
                            </div> 
                            <span> ui-timer </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-touch-phone"></i>
                            </div> 
                            <span> ui-touch-phone </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-travel"></i>
                            </div> 
                            <span> ui-travel </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-unlock"></i>
                            </div> 
                            <span> ui-unlock </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-user-group"></i>
                            </div> 
                            <span> ui-user-group </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-user"></i>
                            </div> 
                            <span> ui-user </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-v-card"></i>
                            </div> 
                            <span> ui-v-card </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-video-chat"></i>
                            </div> 
                            <span> ui-video-chat </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-video-message"></i>
                            </div> 
                            <span> ui-video-message </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-video-play"></i>
                            </div> 
                            <span> ui-video-play </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-video"></i>
                            </div> 
                            <span> ui-video </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-volume"></i>
                            </div> 
                            <span> ui-volume </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-weather"></i>
                            </div> 
                            <span> ui-weather </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-wifi"></i>
                            </div> 
                            <span> ui-wifi </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-zoom-in"></i>
                            </div> 
                            <span> ui-zoom-in </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ui-zoom-out"></i>
                            </div> 
                            <span> ui-zoom-out </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cassette-player"></i>
                            </div> 
                            <span> cassette-player </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cassette"></i>
                            </div> 
                            <span> cassette </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-forward"></i>
                            </div> 
                            <span> forward </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-guiter"></i>
                            </div> 
                            <span> guiter </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-movie"></i>
                            </div> 
                            <span> movie </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-multimedia"></i>
                            </div> 
                            <span> multimedia </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-music-alt"></i>
                            </div> 
                            <span> music-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-music-disk"></i>
                            </div> 
                            <span> music-disk </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-music-note"></i>
                            </div> 
                            <span> music-note </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-music-notes"></i>
                            </div> 
                            <span> music-notes </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-music"></i>
                            </div> 
                            <span> music </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-mute-volume"></i>
                            </div> 
                            <span> mute-volume </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pause"></i>
                            </div> 
                            <span> pause </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-play-alt-1"></i>
                            </div> 
                            <span> play-alt-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-play-alt-2"></i>
                            </div> 
                            <span> play-alt-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-play-alt-3"></i>
                            </div> 
                            <span> play-alt-3 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-play-pause"></i>
                            </div> 
                            <span> play-pause </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-play"></i>
                            </div> 
                            <span> play </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-record"></i>
                            </div> 
                            <span> record </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-retro-music-disk"></i>
                            </div> 
                            <span> retro-music-disk </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rewind"></i>
                            </div> 
                            <span> rewind </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-song-notes"></i>
                            </div> 
                            <span> song-notes </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-sound-wave-alt"></i>
                            </div> 
                            <span> sound-wave-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-sound-wave"></i>
                            </div> 
                            <span> sound-wave </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-stop"></i>
                            </div> 
                            <span> stop </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-video-alt"></i>
                            </div> 
                            <span> video-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-video-cam"></i>
                            </div> 
                            <span> video-cam </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-video-clapper"></i>
                            </div> 
                            <span> video-clapper </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-video"></i>
                            </div> 
                            <span> video </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-volume-bar"></i>
                            </div> 
                            <span> volume-bar </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-volume-down"></i>
                            </div> 
                            <span> volume-down </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-volume-mute"></i>
                            </div> 
                            <span> volume-mute </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-volume-off"></i>
                            </div> 
                            <span> volume-off </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-volume-up"></i>
                            </div> 
                            <span> volume-up </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-youtube-play"></i>
                            </div> 
                            <span> youtube-play </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-2checkout-alt"></i>
                            </div> 
                            <span> 2checkout-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-2checkout"></i>
                            </div> 
                            <span> 2checkout </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-amazon-alt"></i>
                            </div> 
                            <span> amazon-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-amazon"></i>
                            </div> 
                            <span> amazon </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-american-express-alt"></i>
                            </div> 
                            <span> american-express-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-american-express"></i>
                            </div> 
                            <span> american-express </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-apple-pay-alt"></i>
                            </div> 
                            <span> apple-pay-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-apple-pay"></i>
                            </div> 
                            <span> apple-pay </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bank-transfer-alt"></i>
                            </div> 
                            <span> bank-transfer-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bank-transfer"></i>
                            </div> 
                            <span> bank-transfer </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-braintree-alt"></i>
                            </div> 
                            <span> braintree-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-braintree"></i>
                            </div> 
                            <span> braintree </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cash-on-delivery-alt"></i>
                            </div> 
                            <span> cash-on-delivery-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cash-on-delivery"></i>
                            </div> 
                            <span> cash-on-delivery </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-diners-club-alt-1"></i>
                            </div> 
                            <span> diners-club-alt-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-diners-club-alt-2"></i>
                            </div> 
                            <span> diners-club-alt-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-diners-club-alt-3"></i>
                            </div> 
                            <span> diners-club-alt-3 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-diners-club"></i>
                            </div> 
                            <span> diners-club </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-discover-alt"></i>
                            </div> 
                            <span> discover-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-discover"></i>
                            </div> 
                            <span> discover </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-eway-alt"></i>
                            </div> 
                            <span> eway-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-eway"></i>
                            </div> 
                            <span> eway </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-google-wallet-alt-1"></i>
                            </div> 
                            <span> google-wallet-alt-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-google-wallet-alt-2"></i>
                            </div> 
                            <span> google-wallet-alt-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-google-wallet-alt-3"></i>
                            </div> 
                            <span> google-wallet-alt-3 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-google-wallet"></i>
                            </div> 
                            <span> google-wallet </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-jcb-alt"></i>
                            </div> 
                            <span> jcb-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-jcb"></i>
                            </div> 
                            <span> jcb </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-maestro-alt"></i>
                            </div> 
                            <span> maestro-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-maestro"></i>
                            </div> 
                            <span> maestro </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-mastercard-alt"></i>
                            </div> 
                            <span> mastercard-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-mastercard"></i>
                            </div> 
                            <span> mastercard </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-payoneer-alt"></i>
                            </div> 
                            <span> payoneer-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-payoneer"></i>
                            </div> 
                            <span> payoneer </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-paypal-alt"></i>
                            </div> 
                            <span> paypal-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-paypal"></i>
                            </div> 
                            <span> paypal </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-sage-alt"></i>
                            </div> 
                            <span> sage-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-sage"></i>
                            </div> 
                            <span> sage </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-skrill-alt"></i>
                            </div> 
                            <span> skrill-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-skrill"></i>
                            </div> 
                            <span> skrill </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-stripe-alt"></i>
                            </div> 
                            <span> stripe-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-stripe"></i>
                            </div> 
                            <span> stripe </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-visa-alt"></i>
                            </div> 
                            <span> visa-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-visa-electron"></i>
                            </div> 
                            <span> visa-electron </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-visa"></i>
                            </div> 
                            <span> visa </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-western-union-alt"></i>
                            </div> 
                            <span> western-union-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-western-union"></i>
                            </div> 
                            <span> western-union </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-boy"></i>
                            </div> 
                            <span> boy </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-business-man-alt-1"></i>
                            </div> 
                            <span> business-man-alt-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-business-man-alt-2"></i>
                            </div> 
                            <span> business-man-alt-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-business-man-alt-3"></i>
                            </div> 
                            <span> business-man-alt-3 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-business-man"></i>
                            </div> 
                            <span> business-man </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-female"></i>
                            </div> 
                            <span> female </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-funky-man"></i>
                            </div> 
                            <span> funky-man </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-girl-alt"></i>
                            </div> 
                            <span> girl-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-girl"></i>
                            </div> 
                            <span> girl </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-group"></i>
                            </div> 
                            <span> group </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hotel-boy-alt"></i>
                            </div> 
                            <span> hotel-boy-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hotel-boy"></i>
                            </div> 
                            <span> hotel-boy </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-kid"></i>
                            </div> 
                            <span> kid </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-man-in-glasses"></i>
                            </div> 
                            <span> man-in-glasses </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-people"></i>
                            </div> 
                            <span> people </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-support"></i>
                            </div> 
                            <span> support </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-user-alt-1"></i>
                            </div> 
                            <span> user-alt-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-user-alt-2"></i>
                            </div> 
                            <span> user-alt-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-user-alt-3"></i>
                            </div> 
                            <span> user-alt-3 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-user-alt-4"></i>
                            </div> 
                            <span> user-alt-4 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-user-alt-5"></i>
                            </div> 
                            <span> user-alt-5 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-user-alt-6"></i>
                            </div> 
                            <span> user-alt-6 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-user-alt-7"></i>
                            </div> 
                            <span> user-alt-7 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-user-female"></i>
                            </div> 
                            <span> user-female </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-user-male"></i>
                            </div> 
                            <span> user-male </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-user-suited"></i>
                            </div> 
                            <span> user-suited </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-user"></i>
                            </div> 
                            <span> user </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-users-alt-1"></i>
                            </div> 
                            <span> users-alt-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-users-alt-2"></i>
                            </div> 
                            <span> users-alt-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-users-alt-3"></i>
                            </div> 
                            <span> users-alt-3 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-users-alt-4"></i>
                            </div> 
                            <span> users-alt-4 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-users-alt-5"></i>
                            </div> 
                            <span> users-alt-5 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-users-alt-6"></i>
                            </div> 
                            <span> users-alt-6 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-users-social"></i>
                            </div> 
                            <span> users-social </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-users"></i>
                            </div> 
                            <span> users </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-waiter-alt"></i>
                            </div> 
                            <span> waiter-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-waiter"></i>
                            </div> 
                            <span> waiter </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-woman-in-glasses"></i>
                            </div> 
                            <span> woman-in-glasses </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-search-1"></i>
                            </div> 
                            <span> search-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-search-2"></i>
                            </div> 
                            <span> search-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-search-document"></i>
                            </div> 
                            <span> search-document </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-search-folder"></i>
                            </div> 
                            <span> search-folder </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-search-job"></i>
                            </div> 
                            <span> search-job </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-search-map"></i>
                            </div> 
                            <span> search-map </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-search-property"></i>
                            </div> 
                            <span> search-property </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-search-restaurant"></i>
                            </div> 
                            <span> search-restaurant </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-search-stock"></i>
                            </div> 
                            <span> search-stock </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-search-user"></i>
                            </div> 
                            <span> search-user </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-search"></i>
                            </div> 
                            <span> search </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-500px"></i>
                            </div> 
                            <span> 500px </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-aim"></i>
                            </div> 
                            <span> aim </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-badoo"></i>
                            </div> 
                            <span> badoo </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-baidu-tieba"></i>
                            </div> 
                            <span> baidu-tieba </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bbm-messenger"></i>
                            </div> 
                            <span> bbm-messenger </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bebo"></i>
                            </div> 
                            <span> bebo </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-behance"></i>
                            </div> 
                            <span> behance </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-blogger"></i>
                            </div> 
                            <span> blogger </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bootstrap"></i>
                            </div> 
                            <span> bootstrap </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brightkite"></i>
                            </div> 
                            <span> brightkite </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cloudapp"></i>
                            </div> 
                            <span> cloudapp </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-concrete5"></i>
                            </div> 
                            <span> concrete5 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-delicious"></i>
                            </div> 
                            <span> delicious </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-designbump"></i>
                            </div> 
                            <span> designbump </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-designfloat"></i>
                            </div> 
                            <span> designfloat </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-deviantart"></i>
                            </div> 
                            <span> deviantart </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-digg"></i>
                            </div> 
                            <span> digg </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dotcms"></i>
                            </div> 
                            <span> dotcms </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dribbble"></i>
                            </div> 
                            <span> dribbble </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dribble"></i>
                            </div> 
                            <span> dribble </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dropbox"></i>
                            </div> 
                            <span> dropbox </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ebuddy"></i>
                            </div> 
                            <span> ebuddy </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ello"></i>
                            </div> 
                            <span> ello </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ember"></i>
                            </div> 
                            <span> ember </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-envato"></i>
                            </div> 
                            <span> envato </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-evernote"></i>
                            </div> 
                            <span> evernote </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-facebook-messenger"></i>
                            </div> 
                            <span> facebook-messenger </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-facebook"></i>
                            </div> 
                            <span> facebook </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-feedburner"></i>
                            </div> 
                            <span> feedburner </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-flikr"></i>
                            </div> 
                            <span> flikr </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-folkd"></i>
                            </div> 
                            <span> folkd </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-foursquare"></i>
                            </div> 
                            <span> foursquare </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-friendfeed"></i>
                            </div> 
                            <span> friendfeed </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ghost"></i>
                            </div> 
                            <span> ghost </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-github"></i>
                            </div> 
                            <span> github </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-gnome"></i>
                            </div> 
                            <span> gnome </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-google-buzz"></i>
                            </div> 
                            <span> google-buzz </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-google-hangouts"></i>
                            </div> 
                            <span> google-hangouts </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-google-map"></i>
                            </div> 
                            <span> google-map </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-google-plus"></i>
                            </div> 
                            <span> google-plus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-google-talk"></i>
                            </div> 
                            <span> google-talk </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hype-machine"></i>
                            </div> 
                            <span> hype-machine </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-instagram"></i>
                            </div> 
                            <span> instagram </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-kakaotalk"></i>
                            </div> 
                            <span> kakaotalk </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-kickstarter"></i>
                            </div> 
                            <span> kickstarter </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-kik"></i>
                            </div> 
                            <span> kik </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-kiwibox"></i>
                            </div> 
                            <span> kiwibox </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-line-messenger"></i>
                            </div> 
                            <span> line-messenger </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-line"></i>
                            </div> 
                            <span> line </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-linkedin"></i>
                            </div> 
                            <span> linkedin </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-linux-mint"></i>
                            </div> 
                            <span> linux-mint </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-live-messenger"></i>
                            </div> 
                            <span> live-messenger </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-livejournal"></i>
                            </div> 
                            <span> livejournal </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-magento"></i>
                            </div> 
                            <span> magento </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-meetme"></i>
                            </div> 
                            <span> meetme </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-meetup"></i>
                            </div> 
                            <span> meetup </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-mixx"></i>
                            </div> 
                            <span> mixx </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-newsvine"></i>
                            </div> 
                            <span> newsvine </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-nimbuss"></i>
                            </div> 
                            <span> nimbuss </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-odnoklassniki"></i>
                            </div> 
                            <span> odnoklassniki </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-opencart"></i>
                            </div> 
                            <span> opencart </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-oscommerce"></i>
                            </div> 
                            <span> oscommerce </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pandora"></i>
                            </div> 
                            <span> pandora </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-photobucket"></i>
                            </div> 
                            <span> photobucket </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-picasa"></i>
                            </div> 
                            <span> picasa </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pinterest"></i>
                            </div> 
                            <span> pinterest </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-prestashop"></i>
                            </div> 
                            <span> prestashop </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-qik"></i>
                            </div> 
                            <span> qik </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-qq"></i>
                            </div> 
                            <span> qq </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-readernaut"></i>
                            </div> 
                            <span> readernaut </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-reddit"></i>
                            </div> 
                            <span> reddit </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-renren"></i>
                            </div> 
                            <span> renren </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rss"></i>
                            </div> 
                            <span> rss </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-shopify"></i>
                            </div> 
                            <span> shopify </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-silverstripe"></i>
                            </div> 
                            <span> silverstripe </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-skype"></i>
                            </div> 
                            <span> skype </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-slack"></i>
                            </div> 
                            <span> slack </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-slashdot"></i>
                            </div> 
                            <span> slashdot </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-slidshare"></i>
                            </div> 
                            <span> slidshare </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-smugmug"></i>
                            </div> 
                            <span> smugmug </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-snapchat"></i>
                            </div> 
                            <span> snapchat </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-soundcloud"></i>
                            </div> 
                            <span> soundcloud </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-spotify"></i>
                            </div> 
                            <span> spotify </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-stack-exchange"></i>
                            </div> 
                            <span> stack-exchange </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-stack-overflow"></i>
                            </div> 
                            <span> stack-overflow </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-steam"></i>
                            </div> 
                            <span> steam </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-stumbleupon"></i>
                            </div> 
                            <span> stumbleupon </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-tagged"></i>
                            </div> 
                            <span> tagged </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-technorati"></i>
                            </div> 
                            <span> technorati </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-telegram"></i>
                            </div> 
                            <span> telegram </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-tinder"></i>
                            </div> 
                            <span> tinder </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-trello"></i>
                            </div> 
                            <span> trello </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-tumblr"></i>
                            </div> 
                            <span> tumblr </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-twitch"></i>
                            </div> 
                            <span> twitch </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-twitter"></i>
                            </div> 
                            <span> twitter </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-typo3"></i>
                            </div> 
                            <span> typo3 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ubercart"></i>
                            </div> 
                            <span> ubercart </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-viber"></i>
                            </div> 
                            <span> viber </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-viddler"></i>
                            </div> 
                            <span> viddler </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-vimeo"></i>
                            </div> 
                            <span> vimeo </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-vine"></i>
                            </div> 
                            <span> vine </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-virb"></i>
                            </div> 
                            <span> virb </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-virtuemart"></i>
                            </div> 
                            <span> virtuemart </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-vk"></i>
                            </div> 
                            <span> vk </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-wechat"></i>
                            </div> 
                            <span> wechat </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-weibo"></i>
                            </div> 
                            <span> weibo </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-whatsapp"></i>
                            </div> 
                            <span> whatsapp </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-xing"></i>
                            </div> 
                            <span> xing </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-yahoo"></i>
                            </div> 
                            <span> yahoo </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-yelp"></i>
                            </div> 
                            <span> yelp </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-youku"></i>
                            </div> 
                            <span> youku </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-youtube"></i>
                            </div> 
                            <span> youtube </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-zencart"></i>
                            </div> 
                            <span> zencart </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-badminton-birdie"></i>
                            </div> 
                            <span> badminton-birdie </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-baseball"></i>
                            </div> 
                            <span> baseball </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-baseballer"></i>
                            </div> 
                            <span> baseballer </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-basketball-hoop"></i>
                            </div> 
                            <span> basketball-hoop </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-basketball"></i>
                            </div> 
                            <span> basketball </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-billiard-ball"></i>
                            </div> 
                            <span> billiard-ball </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-boot-alt-1"></i>
                            </div> 
                            <span> boot-alt-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-boot-alt-2"></i>
                            </div> 
                            <span> boot-alt-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-boot"></i>
                            </div> 
                            <span> boot </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bowling-alt"></i>
                            </div> 
                            <span> bowling-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bowling"></i>
                            </div> 
                            <span> bowling </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-canoe"></i>
                            </div> 
                            <span> canoe </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cheer-leader"></i>
                            </div> 
                            <span> cheer-leader </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-climbing"></i>
                            </div> 
                            <span> climbing </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-corner"></i>
                            </div> 
                            <span> corner </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-field-alt"></i>
                            </div> 
                            <span> field-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-field"></i>
                            </div> 
                            <span> field </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-football-alt"></i>
                            </div> 
                            <span> football-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-football-american"></i>
                            </div> 
                            <span> football-american </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-football"></i>
                            </div> 
                            <span> football </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-foul"></i>
                            </div> 
                            <span> foul </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-goal-keeper"></i>
                            </div> 
                            <span> goal-keeper </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-goal"></i>
                            </div> 
                            <span> goal </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-golf-alt"></i>
                            </div> 
                            <span> golf-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-golf-bag"></i>
                            </div> 
                            <span> golf-bag </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-golf-cart"></i>
                            </div> 
                            <span> golf-cart </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-golf-field"></i>
                            </div> 
                            <span> golf-field </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-golf"></i>
                            </div> 
                            <span> golf </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-golfer"></i>
                            </div> 
                            <span> golfer </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-helmet"></i>
                            </div> 
                            <span> helmet </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hockey-alt"></i>
                            </div> 
                            <span> hockey-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hockey"></i>
                            </div> 
                            <span> hockey </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ice-skate"></i>
                            </div> 
                            <span> ice-skate </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-jersey-alt"></i>
                            </div> 
                            <span> jersey-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-jersey"></i>
                            </div> 
                            <span> jersey </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-jumping"></i>
                            </div> 
                            <span> jumping </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-kick"></i>
                            </div> 
                            <span> kick </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-leg"></i>
                            </div> 
                            <span> leg </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-match-review"></i>
                            </div> 
                            <span> match-review </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-medal-sport"></i>
                            </div> 
                            <span> medal-sport </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-offside"></i>
                            </div> 
                            <span> offside </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-olympic-logo"></i>
                            </div> 
                            <span> olympic-logo </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-olympic"></i>
                            </div> 
                            <span> olympic </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-padding"></i>
                            </div> 
                            <span> padding </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-penalty-card"></i>
                            </div> 
                            <span> penalty-card </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-racer"></i>
                            </div> 
                            <span> racer </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-racing-car"></i>
                            </div> 
                            <span> racing-car </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-racing-flag-alt"></i>
                            </div> 
                            <span> racing-flag-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-racing-flag"></i>
                            </div> 
                            <span> racing-flag </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-racings-wheel"></i>
                            </div> 
                            <span> racings-wheel </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-referee"></i>
                            </div> 
                            <span> referee </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-refree-jersey"></i>
                            </div> 
                            <span> refree-jersey </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-result-sport"></i>
                            </div> 
                            <span> result-sport </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rugby-ball"></i>
                            </div> 
                            <span> rugby-ball </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rugby-player"></i>
                            </div> 
                            <span> rugby-player </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rugby"></i>
                            </div> 
                            <span> rugby </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-runner-alt-1"></i>
                            </div> 
                            <span> runner-alt-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-runner-alt-2"></i>
                            </div> 
                            <span> runner-alt-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-runner"></i>
                            </div> 
                            <span> runner </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-score-board"></i>
                            </div> 
                            <span> score-board </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-skiing-man"></i>
                            </div> 
                            <span> skiing-man </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-skydiving-goggles"></i>
                            </div> 
                            <span> skydiving-goggles </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-snow-mobile"></i>
                            </div> 
                            <span> snow-mobile </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-steering"></i>
                            </div> 
                            <span> steering </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-stopwatch"></i>
                            </div> 
                            <span> stopwatch </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-substitute"></i>
                            </div> 
                            <span> substitute </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-swimmer"></i>
                            </div> 
                            <span> swimmer </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-table-tennis"></i>
                            </div> 
                            <span> table-tennis </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-team-alt"></i>
                            </div> 
                            <span> team-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-team"></i>
                            </div> 
                            <span> team </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-tennis-player"></i>
                            </div> 
                            <span> tennis-player </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-tennis"></i>
                            </div> 
                            <span> tennis </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-tracking"></i>
                            </div> 
                            <span> tracking </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-trophy-alt"></i>
                            </div> 
                            <span> trophy-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-trophy"></i>
                            </div> 
                            <span> trophy </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-volleyball-alt"></i>
                            </div> 
                            <span> volleyball-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-volleyball-fire"></i>
                            </div> 
                            <span> volleyball-fire </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-volleyball"></i>
                            </div> 
                            <span> volleyball </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-water-bottle"></i>
                            </div> 
                            <span> water-bottle </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-whistle-alt"></i>
                            </div> 
                            <span> whistle-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-whistle"></i>
                            </div> 
                            <span> whistle </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-win-trophy"></i>
                            </div> 
                            <span> win-trophy </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-align-center"></i>
                            </div> 
                            <span> align-center </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-align-left"></i>
                            </div> 
                            <span> align-left </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-align-right"></i>
                            </div> 
                            <span> align-right </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-all-caps"></i>
                            </div> 
                            <span> all-caps </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bold"></i>
                            </div> 
                            <span> bold </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-brush"></i>
                            </div> 
                            <span> brush </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-clip-board"></i>
                            </div> 
                            <span> clip-board </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-code-alt"></i>
                            </div> 
                            <span> code-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-color-bucket"></i>
                            </div> 
                            <span> color-bucket </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-color-picker"></i>
                            </div> 
                            <span> color-picker </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-copy-invert"></i>
                            </div> 
                            <span> copy-invert </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-copy"></i>
                            </div> 
                            <span> copy </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cut"></i>
                            </div> 
                            <span> cut </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-delete-alt"></i>
                            </div> 
                            <span> delete-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-edit-alt"></i>
                            </div> 
                            <span> edit-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-eraser-alt"></i>
                            </div> 
                            <span> eraser-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-font"></i>
                            </div> 
                            <span> font </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-heading"></i>
                            </div> 
                            <span> heading </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-indent"></i>
                            </div> 
                            <span> indent </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-italic-alt"></i>
                            </div> 
                            <span> italic-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-italic"></i>
                            </div> 
                            <span> italic </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-justify-all"></i>
                            </div> 
                            <span> justify-all </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-justify-center"></i>
                            </div> 
                            <span> justify-center </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-justify-left"></i>
                            </div> 
                            <span> justify-left </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-justify-right"></i>
                            </div> 
                            <span> justify-right </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-link-broken"></i>
                            </div> 
                            <span> link-broken </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-outdent"></i>
                            </div> 
                            <span> outdent </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-paper-clip"></i>
                            </div> 
                            <span> paper-clip </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-paragraph"></i>
                            </div> 
                            <span> paragraph </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pin"></i>
                            </div> 
                            <span> pin </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-printer"></i>
                            </div> 
                            <span> printer </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-redo"></i>
                            </div> 
                            <span> redo </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rotation"></i>
                            </div> 
                            <span> rotation </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-save"></i>
                            </div> 
                            <span> save </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-small-cap"></i>
                            </div> 
                            <span> small-cap </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-strike-through"></i>
                            </div> 
                            <span> strike-through </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-sub-listing"></i>
                            </div> 
                            <span> sub-listing </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-subscript"></i>
                            </div> 
                            <span> subscript </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-superscript"></i>
                            </div> 
                            <span> superscript </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-table"></i>
                            </div> 
                            <span> table </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-text-height"></i>
                            </div> 
                            <span> text-height </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-text-width"></i>
                            </div> 
                            <span> text-width </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-trash"></i>
                            </div> 
                            <span> trash </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-underline"></i>
                            </div> 
                            <span> underline </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-undo"></i>
                            </div> 
                            <span> undo </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-air-balloon"></i>
                            </div> 
                            <span> air-balloon </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-airplane-alt"></i>
                            </div> 
                            <span> airplane-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-airplane"></i>
                            </div> 
                            <span> airplane </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-articulated-truck"></i>
                            </div> 
                            <span> articulated-truck </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-auto-mobile"></i>
                            </div> 
                            <span> auto-mobile </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-auto-rickshaw"></i>
                            </div> 
                            <span> auto-rickshaw </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bicycle-alt-1"></i>
                            </div> 
                            <span> bicycle-alt-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bicycle-alt-2"></i>
                            </div> 
                            <span> bicycle-alt-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bicycle"></i>
                            </div> 
                            <span> bicycle </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bus-alt-1"></i>
                            </div> 
                            <span> bus-alt-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bus-alt-2"></i>
                            </div> 
                            <span> bus-alt-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bus-alt-3"></i>
                            </div> 
                            <span> bus-alt-3 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bus"></i>
                            </div> 
                            <span> bus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cab"></i>
                            </div> 
                            <span> cab </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cable-car"></i>
                            </div> 
                            <span> cable-car </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-car-alt-1"></i>
                            </div> 
                            <span> car-alt-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-car-alt-2"></i>
                            </div> 
                            <span> car-alt-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-car-alt-3"></i>
                            </div> 
                            <span> car-alt-3 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-car-alt-4"></i>
                            </div> 
                            <span> car-alt-4 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-car"></i>
                            </div> 
                            <span> car </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-delivery-time"></i>
                            </div> 
                            <span> delivery-time </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-fast-delivery"></i>
                            </div> 
                            <span> fast-delivery </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-fire-truck-alt"></i>
                            </div> 
                            <span> fire-truck-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-fire-truck"></i>
                            </div> 
                            <span> fire-truck </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-free-delivery"></i>
                            </div> 
                            <span> free-delivery </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-helicopter"></i>
                            </div> 
                            <span> helicopter </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-motor-bike-alt"></i>
                            </div> 
                            <span> motor-bike-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-motor-bike"></i>
                            </div> 
                            <span> motor-bike </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-motor-biker"></i>
                            </div> 
                            <span> motor-biker </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-oil-truck"></i>
                            </div> 
                            <span> oil-truck </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rickshaw"></i>
                            </div> 
                            <span> rickshaw </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rocket-alt-1"></i>
                            </div> 
                            <span> rocket-alt-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rocket-alt-2"></i>
                            </div> 
                            <span> rocket-alt-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rocket"></i>
                            </div> 
                            <span> rocket </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-sail-boat-alt-1"></i>
                            </div> 
                            <span> sail-boat-alt-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-sail-boat-alt-2"></i>
                            </div> 
                            <span> sail-boat-alt-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-sail-boat"></i>
                            </div> 
                            <span> sail-boat </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-scooter"></i>
                            </div> 
                            <span> scooter </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-sea-plane"></i>
                            </div> 
                            <span> sea-plane </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ship-alt"></i>
                            </div> 
                            <span> ship-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ship"></i>
                            </div> 
                            <span> ship </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-speed-boat"></i>
                            </div> 
                            <span> speed-boat </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-taxi"></i>
                            </div> 
                            <span> taxi </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-tractor"></i>
                            </div> 
                            <span> tractor </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-train-line"></i>
                            </div> 
                            <span> train-line </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-train-steam"></i>
                            </div> 
                            <span> train-steam </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-tram"></i>
                            </div> 
                            <span> tram </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-truck-alt"></i>
                            </div> 
                            <span> truck-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-truck-loaded"></i>
                            </div> 
                            <span> truck-loaded </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-truck"></i>
                            </div> 
                            <span> truck </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-van-alt"></i>
                            </div> 
                            <span> van-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-van"></i>
                            </div> 
                            <span> van </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-yacht"></i>
                            </div> 
                            <span> yacht </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-5-star-hotel"></i>
                            </div> 
                            <span> 5-star-hotel </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-air-ticket"></i>
                            </div> 
                            <span> air-ticket </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-beach-bed"></i>
                            </div> 
                            <span> beach-bed </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-beach"></i>
                            </div> 
                            <span> beach </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-camping-vest"></i>
                            </div> 
                            <span> camping-vest </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-direction-sign"></i>
                            </div> 
                            <span> direction-sign </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hill-side"></i>
                            </div> 
                            <span> hill-side </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hill"></i>
                            </div> 
                            <span> hill </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hotel"></i>
                            </div> 
                            <span> hotel </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-island-alt"></i>
                            </div> 
                            <span> island-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-island"></i>
                            </div> 
                            <span> island </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-sandals-female"></i>
                            </div> 
                            <span> sandals-female </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-sandals-male"></i>
                            </div> 
                            <span> sandals-male </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-travelling"></i>
                            </div> 
                            <span> travelling </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-breakdown"></i>
                            </div> 
                            <span> breakdown </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-celsius"></i>
                            </div> 
                            <span> celsius </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-clouds"></i>
                            </div> 
                            <span> clouds </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cloudy"></i>
                            </div> 
                            <span> cloudy </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dust"></i>
                            </div> 
                            <span> dust </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-eclipse"></i>
                            </div> 
                            <span> eclipse </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-fahrenheit"></i>
                            </div> 
                            <span> fahrenheit </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-forest-fire"></i>
                            </div> 
                            <span> forest-fire </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-full-night"></i>
                            </div> 
                            <span> full-night </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-full-sunny"></i>
                            </div> 
                            <span> full-sunny </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hail-night"></i>
                            </div> 
                            <span> hail-night </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hail-rainy-night"></i>
                            </div> 
                            <span> hail-rainy-night </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hail-rainy-sunny"></i>
                            </div> 
                            <span> hail-rainy-sunny </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hail-rainy"></i>
                            </div> 
                            <span> hail-rainy </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hail-sunny"></i>
                            </div> 
                            <span> hail-sunny </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hail-thunder-night"></i>
                            </div> 
                            <span> hail-thunder-night </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hail-thunder-sunny"></i>
                            </div> 
                            <span> hail-thunder-sunny </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hail-thunder"></i>
                            </div> 
                            <span> hail-thunder </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hail"></i>
                            </div> 
                            <span> hail </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hill-night"></i>
                            </div> 
                            <span> hill-night </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hill-sunny"></i>
                            </div> 
                            <span> hill-sunny </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hurricane"></i>
                            </div> 
                            <span> hurricane </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-meteor"></i>
                            </div> 
                            <span> meteor </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-night"></i>
                            </div> 
                            <span> night </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rainy-night"></i>
                            </div> 
                            <span> rainy-night </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rainy-sunny"></i>
                            </div> 
                            <span> rainy-sunny </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rainy-thunder"></i>
                            </div> 
                            <span> rainy-thunder </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rainy"></i>
                            </div> 
                            <span> rainy </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-snow-alt"></i>
                            </div> 
                            <span> snow-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-snow-flake"></i>
                            </div> 
                            <span> snow-flake </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-snow-temp"></i>
                            </div> 
                            <span> snow-temp </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-snow"></i>
                            </div> 
                            <span> snow </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-snowy-hail"></i>
                            </div> 
                            <span> snowy-hail </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-snowy-night-hail"></i>
                            </div> 
                            <span> snowy-night-hail </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-snowy-night-rainy"></i>
                            </div> 
                            <span> snowy-night-rainy </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-snowy-night"></i>
                            </div> 
                            <span> snowy-night </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-snowy-rainy"></i>
                            </div> 
                            <span> snowy-rainy </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-snowy-sunny-hail"></i>
                            </div> 
                            <span> snowy-sunny-hail </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-snowy-sunny-rainy"></i>
                            </div> 
                            <span> snowy-sunny-rainy </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-snowy-sunny"></i>
                            </div> 
                            <span> snowy-sunny </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-snowy-thunder-night"></i>
                            </div> 
                            <span> snowy-thunder-night </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-snowy-thunder-sunny"></i>
                            </div> 
                            <span> snowy-thunder-sunny </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-snowy-thunder"></i>
                            </div> 
                            <span> snowy-thunder </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-snowy-windy-night"></i>
                            </div> 
                            <span> snowy-windy-night </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-snowy-windy-sunny"></i>
                            </div> 
                            <span> snowy-windy-sunny </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-snowy-windy"></i>
                            </div> 
                            <span> snowy-windy </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-snowy"></i>
                            </div> 
                            <span> snowy </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-sun-alt"></i>
                            </div> 
                            <span> sun-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-sun-rise"></i>
                            </div> 
                            <span> sun-rise </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-sun-set"></i>
                            </div> 
                            <span> sun-set </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-sun"></i>
                            </div> 
                            <span> sun </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-sunny-day-temp"></i>
                            </div> 
                            <span> sunny-day-temp </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-sunny"></i>
                            </div> 
                            <span> sunny </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-thunder-light"></i>
                            </div> 
                            <span> thunder-light </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-tornado"></i>
                            </div> 
                            <span> tornado </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-umbrella-alt"></i>
                            </div> 
                            <span> umbrella-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-umbrella"></i>
                            </div> 
                            <span> umbrella </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-volcano"></i>
                            </div> 
                            <span> volcano </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-wave"></i>
                            </div> 
                            <span> wave </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-wind-scale-0"></i>
                            </div> 
                            <span> wind-scale-0 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-wind-scale-1"></i>
                            </div> 
                            <span> wind-scale-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-wind-scale-10"></i>
                            </div> 
                            <span> wind-scale-10 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-wind-scale-11"></i>
                            </div> 
                            <span> wind-scale-11 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-wind-scale-12"></i>
                            </div> 
                            <span> wind-scale-12 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-wind-scale-2"></i>
                            </div> 
                            <span> wind-scale-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-wind-scale-3"></i>
                            </div> 
                            <span> wind-scale-3 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-wind-scale-4"></i>
                            </div> 
                            <span> wind-scale-4 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-wind-scale-5"></i>
                            </div> 
                            <span> wind-scale-5 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-wind-scale-6"></i>
                            </div> 
                            <span> wind-scale-6 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-wind-scale-7"></i>
                            </div> 
                            <span> wind-scale-7 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-wind-scale-8"></i>
                            </div> 
                            <span> wind-scale-8 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-wind-scale-9"></i>
                            </div> 
                            <span> wind-scale-9 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-wind-waves"></i>
                            </div> 
                            <span> wind-waves </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-wind"></i>
                            </div> 
                            <span> wind </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-windy-hail"></i>
                            </div> 
                            <span> windy-hail </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-windy-night"></i>
                            </div> 
                            <span> windy-night </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-windy-raining"></i>
                            </div> 
                            <span> windy-raining </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-windy-sunny"></i>
                            </div> 
                            <span> windy-sunny </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-windy-thunder-raining"></i>
                            </div> 
                            <span> windy-thunder-raining </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-windy-thunder"></i>
                            </div> 
                            <span> windy-thunder </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-windy"></i>
                            </div> 
                            <span> windy </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-addons"></i>
                            </div> 
                            <span> addons </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-address-book"></i>
                            </div> 
                            <span> address-book </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-adjust"></i>
                            </div> 
                            <span> adjust </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-alarm"></i>
                            </div> 
                            <span> alarm </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-anchor"></i>
                            </div> 
                            <span> anchor </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-archive"></i>
                            </div> 
                            <span> archive </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-at"></i>
                            </div> 
                            <span> at </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-attachment"></i>
                            </div> 
                            <span> attachment </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-audio"></i>
                            </div> 
                            <span> audio </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-automation"></i>
                            </div> 
                            <span> automation </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-badge"></i>
                            </div> 
                            <span> badge </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bag-alt"></i>
                            </div> 
                            <span> bag-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bag"></i>
                            </div> 
                            <span> bag </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ban"></i>
                            </div> 
                            <span> ban </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bar-code"></i>
                            </div> 
                            <span> bar-code </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bars"></i>
                            </div> 
                            <span> bars </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-basket"></i>
                            </div> 
                            <span> basket </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-battery-empty"></i>
                            </div> 
                            <span> battery-empty </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-battery-full"></i>
                            </div> 
                            <span> battery-full </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-battery-half"></i>
                            </div> 
                            <span> battery-half </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-battery-low"></i>
                            </div> 
                            <span> battery-low </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-beaker"></i>
                            </div> 
                            <span> beaker </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-beard"></i>
                            </div> 
                            <span> beard </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bed"></i>
                            </div> 
                            <span> bed </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bell"></i>
                            </div> 
                            <span> bell </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-beverage"></i>
                            </div> 
                            <span> beverage </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bill"></i>
                            </div> 
                            <span> bill </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bin"></i>
                            </div> 
                            <span> bin </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-binary"></i>
                            </div> 
                            <span> binary </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-binoculars"></i>
                            </div> 
                            <span> binoculars </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bluetooth"></i>
                            </div> 
                            <span> bluetooth </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bomb"></i>
                            </div> 
                            <span> bomb </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-book-mark"></i>
                            </div> 
                            <span> book-mark </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-box"></i>
                            </div> 
                            <span> box </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-briefcase"></i>
                            </div> 
                            <span> briefcase </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-broken"></i>
                            </div> 
                            <span> broken </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bucket"></i>
                            </div> 
                            <span> bucket </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bucket1"></i>
                            </div> 
                            <span> bucket1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bucket2"></i>
                            </div> 
                            <span> bucket2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bug"></i>
                            </div> 
                            <span> bug </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-building"></i>
                            </div> 
                            <span> building </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bulb-alt"></i>
                            </div> 
                            <span> bulb-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bullet"></i>
                            </div> 
                            <span> bullet </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bullhorn"></i>
                            </div> 
                            <span> bullhorn </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-bullseye"></i>
                            </div> 
                            <span> bullseye </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-calendar"></i>
                            </div> 
                            <span> calendar </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-camera-alt"></i>
                            </div> 
                            <span> camera-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-camera"></i>
                            </div> 
                            <span> camera </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-card"></i>
                            </div> 
                            <span> card </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cart-alt"></i>
                            </div> 
                            <span> cart-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cart"></i>
                            </div> 
                            <span> cart </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cc"></i>
                            </div> 
                            <span> cc </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-charging"></i>
                            </div> 
                            <span> charging </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-chat"></i>
                            </div> 
                            <span> chat </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-check-alt"></i>
                            </div> 
                            <span> check-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-check-circled"></i>
                            </div> 
                            <span> check-circled </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-check"></i>
                            </div> 
                            <span> check </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-checked"></i>
                            </div> 
                            <span> checked </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-children-care"></i>
                            </div> 
                            <span> children-care </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-clip"></i>
                            </div> 
                            <span> clip </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-clock-time"></i>
                            </div> 
                            <span> clock-time </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-close-circled"></i>
                            </div> 
                            <span> close-circled </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-close-line-circled"></i>
                            </div> 
                            <span> close-line-circled </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-close-line-squared-alt"></i>
                            </div> 
                            <span> close-line-squared-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-close-line-squared"></i>
                            </div> 
                            <span> close-line-squared </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-close-line"></i>
                            </div> 
                            <span> close-line </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-close-squared-alt"></i>
                            </div> 
                            <span> close-squared-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-close-squared"></i>
                            </div> 
                            <span> close-squared </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-close"></i>
                            </div> 
                            <span> close </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cloud-download"></i>
                            </div> 
                            <span> cloud-download </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cloud-refresh"></i>
                            </div> 
                            <span> cloud-refresh </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cloud-upload"></i>
                            </div> 
                            <span> cloud-upload </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cloud"></i>
                            </div> 
                            <span> cloud </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-code-not-allowed"></i>
                            </div> 
                            <span> code-not-allowed </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-code"></i>
                            </div> 
                            <span> code </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-comment"></i>
                            </div> 
                            <span> comment </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-compass-alt"></i>
                            </div> 
                            <span> compass-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-compass"></i>
                            </div> 
                            <span> compass </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-computer"></i>
                            </div> 
                            <span> computer </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-connection"></i>
                            </div> 
                            <span> connection </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-console"></i>
                            </div> 
                            <span> console </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-contacts"></i>
                            </div> 
                            <span> contacts </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-contrast"></i>
                            </div> 
                            <span> contrast </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-copyright"></i>
                            </div> 
                            <span> copyright </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-credit-card"></i>
                            </div> 
                            <span> credit-card </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-crop"></i>
                            </div> 
                            <span> crop </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-crown"></i>
                            </div> 
                            <span> crown </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cube"></i>
                            </div> 
                            <span> cube </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-cubes"></i>
                            </div> 
                            <span> cubes </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dashboard-web"></i>
                            </div> 
                            <span> dashboard-web </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dashboard"></i>
                            </div> 
                            <span> dashboard </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-data"></i>
                            </div> 
                            <span> data </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-database-add"></i>
                            </div> 
                            <span> database-add </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-database-locked"></i>
                            </div> 
                            <span> database-locked </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-database-remove"></i>
                            </div> 
                            <span> database-remove </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-database"></i>
                            </div> 
                            <span> database </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-delete"></i>
                            </div> 
                            <span> delete </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-diamond"></i>
                            </div> 
                            <span> diamond </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dice-multiple"></i>
                            </div> 
                            <span> dice-multiple </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-dice"></i>
                            </div> 
                            <span> dice </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-disc"></i>
                            </div> 
                            <span> disc </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-diskette"></i>
                            </div> 
                            <span> diskette </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-document-folder"></i>
                            </div> 
                            <span> document-folder </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-download-alt"></i>
                            </div> 
                            <span> download-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-download"></i>
                            </div> 
                            <span> download </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-downloaded"></i>
                            </div> 
                            <span> downloaded </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-drag"></i>
                            </div> 
                            <span> drag </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-drag1"></i>
                            </div> 
                            <span> drag1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-drag2"></i>
                            </div> 
                            <span> drag2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-drag3"></i>
                            </div> 
                            <span> drag3 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-earth"></i>
                            </div> 
                            <span> earth </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ebook"></i>
                            </div> 
                            <span> ebook </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-edit"></i>
                            </div> 
                            <span> edit </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-eject"></i>
                            </div> 
                            <span> eject </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-email"></i>
                            </div> 
                            <span> email </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-envelope-open"></i>
                            </div> 
                            <span> envelope-open </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-envelope"></i>
                            </div> 
                            <span> envelope </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-eraser"></i>
                            </div> 
                            <span> eraser </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-error"></i>
                            </div> 
                            <span> error </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-excavator"></i>
                            </div> 
                            <span> excavator </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-exchange"></i>
                            </div> 
                            <span> exchange </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-exclamation-circle"></i>
                            </div> 
                            <span> exclamation-circle </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-exclamation-square"></i>
                            </div> 
                            <span> exclamation-square </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-exclamation-tringle"></i>
                            </div> 
                            <span> exclamation-tringle </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-exclamation"></i>
                            </div> 
                            <span> exclamation </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-exit"></i>
                            </div> 
                            <span> exit </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-expand"></i>
                            </div> 
                            <span> expand </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-external-link"></i>
                            </div> 
                            <span> external-link </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-external"></i>
                            </div> 
                            <span> external </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-eye-alt"></i>
                            </div> 
                            <span> eye-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-eye-blocked"></i>
                            </div> 
                            <span> eye-blocked </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-eye-dropper"></i>
                            </div> 
                            <span> eye-dropper </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-eye"></i>
                            </div> 
                            <span> eye </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-favourite"></i>
                            </div> 
                            <span> favourite </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-fax"></i>
                            </div> 
                            <span> fax </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-file-fill"></i>
                            </div> 
                            <span> file-fill </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-film"></i>
                            </div> 
                            <span> film </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-filter"></i>
                            </div> 
                            <span> filter </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-fire-alt"></i>
                            </div> 
                            <span> fire-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-fire-burn"></i>
                            </div> 
                            <span> fire-burn </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-fire"></i>
                            </div> 
                            <span> fire </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-flag-alt-1"></i>
                            </div> 
                            <span> flag-alt-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-flag-alt-2"></i>
                            </div> 
                            <span> flag-alt-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-flag"></i>
                            </div> 
                            <span> flag </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-flame-torch"></i>
                            </div> 
                            <span> flame-torch </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-flash-light"></i>
                            </div> 
                            <span> flash-light </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-flash"></i>
                            </div> 
                            <span> flash </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-flask"></i>
                            </div> 
                            <span> flask </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-focus"></i>
                            </div> 
                            <span> focus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-folder-open"></i>
                            </div> 
                            <span> folder-open </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-folder"></i>
                            </div> 
                            <span> folder </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-foot-print"></i>
                            </div> 
                            <span> foot-print </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-garbage"></i>
                            </div> 
                            <span> garbage </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-gear-alt"></i>
                            </div> 
                            <span> gear-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-gear"></i>
                            </div> 
                            <span> gear </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-gears"></i>
                            </div> 
                            <span> gears </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-gift"></i>
                            </div> 
                            <span> gift </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-glass"></i>
                            </div> 
                            <span> glass </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-globe"></i>
                            </div> 
                            <span> globe </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-graffiti"></i>
                            </div> 
                            <span> graffiti </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-grocery"></i>
                            </div> 
                            <span> grocery </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hand"></i>
                            </div> 
                            <span> hand </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hanger"></i>
                            </div> 
                            <span> hanger </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hard-disk"></i>
                            </div> 
                            <span> hard-disk </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-heart-alt"></i>
                            </div> 
                            <span> heart-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-heart"></i>
                            </div> 
                            <span> heart </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-history"></i>
                            </div> 
                            <span> history </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-home"></i>
                            </div> 
                            <span> home </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-horn"></i>
                            </div> 
                            <span> horn </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-hour-glass"></i>
                            </div> 
                            <span> hour-glass </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-id"></i>
                            </div> 
                            <span> id </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-image"></i>
                            </div> 
                            <span> image </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-inbox"></i>
                            </div> 
                            <span> inbox </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-infinite"></i>
                            </div> 
                            <span> infinite </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-info-circle"></i>
                            </div> 
                            <span> info-circle </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-info-square"></i>
                            </div> 
                            <span> info-square </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-info"></i>
                            </div> 
                            <span> info </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-institution"></i>
                            </div> 
                            <span> institution </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-interface"></i>
                            </div> 
                            <span> interface </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-invisible"></i>
                            </div> 
                            <span> invisible </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-jacket"></i>
                            </div> 
                            <span> jacket </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-jar"></i>
                            </div> 
                            <span> jar </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-jewlery"></i>
                            </div> 
                            <span> jewlery </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-karate"></i>
                            </div> 
                            <span> karate </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-key-hole"></i>
                            </div> 
                            <span> key-hole </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-key"></i>
                            </div> 
                            <span> key </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-label"></i>
                            </div> 
                            <span> label </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-lamp"></i>
                            </div> 
                            <span> lamp </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-layers"></i>
                            </div> 
                            <span> layers </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-layout"></i>
                            </div> 
                            <span> layout </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-leaf"></i>
                            </div> 
                            <span> leaf </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-leaflet"></i>
                            </div> 
                            <span> leaflet </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-learn"></i>
                            </div> 
                            <span> learn </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-lego"></i>
                            </div> 
                            <span> lego </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-lens"></i>
                            </div> 
                            <span> lens </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-letter"></i>
                            </div> 
                            <span> letter </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-letterbox"></i>
                            </div> 
                            <span> letterbox </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-library"></i>
                            </div> 
                            <span> library </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-license"></i>
                            </div> 
                            <span> license </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-life-bouy"></i>
                            </div> 
                            <span> life-bouy </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-life-buoy"></i>
                            </div> 
                            <span> life-buoy </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-life-jacket"></i>
                            </div> 
                            <span> life-jacket </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-life-ring"></i>
                            </div> 
                            <span> life-ring </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-light-bulb"></i>
                            </div> 
                            <span> light-bulb </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-lighter"></i>
                            </div> 
                            <span> lighter </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-lightning-ray"></i>
                            </div> 
                            <span> lightning-ray </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-like"></i>
                            </div> 
                            <span> like </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-line-height"></i>
                            </div> 
                            <span> line-height </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-link-alt"></i>
                            </div> 
                            <span> link-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-link"></i>
                            </div> 
                            <span> link </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-list"></i>
                            </div> 
                            <span> list </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-listening"></i>
                            </div> 
                            <span> listening </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-listine-dots"></i>
                            </div> 
                            <span> listine-dots </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-listing-box"></i>
                            </div> 
                            <span> listing-box </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-listing-number"></i>
                            </div> 
                            <span> listing-number </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-live-support"></i>
                            </div> 
                            <span> live-support </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-location-arrow"></i>
                            </div> 
                            <span> location-arrow </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-location-pin"></i>
                            </div> 
                            <span> location-pin </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-lock"></i>
                            </div> 
                            <span> lock </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-login"></i>
                            </div> 
                            <span> login </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-logout"></i>
                            </div> 
                            <span> logout </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-lollipop"></i>
                            </div> 
                            <span> lollipop </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-long-drive"></i>
                            </div> 
                            <span> long-drive </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-look"></i>
                            </div> 
                            <span> look </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-loop"></i>
                            </div> 
                            <span> loop </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-luggage"></i>
                            </div> 
                            <span> luggage </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-lunch"></i>
                            </div> 
                            <span> lunch </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-lungs"></i>
                            </div> 
                            <span> lungs </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-magic-alt"></i>
                            </div> 
                            <span> magic-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-magic"></i>
                            </div> 
                            <span> magic </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-magnet"></i>
                            </div> 
                            <span> magnet </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-mail-box"></i>
                            </div> 
                            <span> mail-box </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-mail"></i>
                            </div> 
                            <span> mail </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-male"></i>
                            </div> 
                            <span> male </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-map-pins"></i>
                            </div> 
                            <span> map-pins </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-map"></i>
                            </div> 
                            <span> map </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-maximize"></i>
                            </div> 
                            <span> maximize </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-measure"></i>
                            </div> 
                            <span> measure </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-medicine"></i>
                            </div> 
                            <span> medicine </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-mega-phone"></i>
                            </div> 
                            <span> mega-phone </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-megaphone-alt"></i>
                            </div> 
                            <span> megaphone-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-megaphone"></i>
                            </div> 
                            <span> megaphone </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-memorial"></i>
                            </div> 
                            <span> memorial </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-memory-card"></i>
                            </div> 
                            <span> memory-card </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-mic-mute"></i>
                            </div> 
                            <span> mic-mute </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-mic"></i>
                            </div> 
                            <span> mic </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-military"></i>
                            </div> 
                            <span> military </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-mill"></i>
                            </div> 
                            <span> mill </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-minus-circle"></i>
                            </div> 
                            <span> minus-circle </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-minus-square"></i>
                            </div> 
                            <span> minus-square </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-minus"></i>
                            </div> 
                            <span> minus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-mobile-phone"></i>
                            </div> 
                            <span> mobile-phone </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-molecule"></i>
                            </div> 
                            <span> molecule </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-money"></i>
                            </div> 
                            <span> money </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-moon"></i>
                            </div> 
                            <span> moon </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-mop"></i>
                            </div> 
                            <span> mop </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-muffin"></i>
                            </div> 
                            <span> muffin </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-mustache"></i>
                            </div> 
                            <span> mustache </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-navigation-menu"></i>
                            </div> 
                            <span> navigation-menu </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-navigation"></i>
                            </div> 
                            <span> navigation </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-network-tower"></i>
                            </div> 
                            <span> network-tower </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-network"></i>
                            </div> 
                            <span> network </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-news"></i>
                            </div> 
                            <span> news </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-newspaper"></i>
                            </div> 
                            <span> newspaper </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-no-smoking"></i>
                            </div> 
                            <span> no-smoking </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-not-allowed"></i>
                            </div> 
                            <span> not-allowed </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-notebook"></i>
                            </div> 
                            <span> notebook </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-notepad"></i>
                            </div> 
                            <span> notepad </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-notification"></i>
                            </div> 
                            <span> notification </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-numbered"></i>
                            </div> 
                            <span> numbered </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-opposite"></i>
                            </div> 
                            <span> opposite </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-optic"></i>
                            </div> 
                            <span> optic </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-options"></i>
                            </div> 
                            <span> options </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-package"></i>
                            </div> 
                            <span> package </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-page"></i>
                            </div> 
                            <span> page </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-paint"></i>
                            </div> 
                            <span> paint </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-paper-plane"></i>
                            </div> 
                            <span> paper-plane </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-paperclip"></i>
                            </div> 
                            <span> paperclip </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-papers"></i>
                            </div> 
                            <span> papers </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pay"></i>
                            </div> 
                            <span> pay </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-penguin-linux"></i>
                            </div> 
                            <span> penguin-linux </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pestle"></i>
                            </div> 
                            <span> pestle </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-phone-circle"></i>
                            </div> 
                            <span> phone-circle </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-phone"></i>
                            </div> 
                            <span> phone </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-picture"></i>
                            </div> 
                            <span> picture </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pine"></i>
                            </div> 
                            <span> pine </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-pixels"></i>
                            </div> 
                            <span> pixels </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-plugin"></i>
                            </div> 
                            <span> plugin </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-plus-circle"></i>
                            </div> 
                            <span> plus-circle </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-plus-square"></i>
                            </div> 
                            <span> plus-square </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-plus"></i>
                            </div> 
                            <span> plus </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-polygonal"></i>
                            </div> 
                            <span> polygonal </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-power"></i>
                            </div> 
                            <span> power </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-price"></i>
                            </div> 
                            <span> price </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-print"></i>
                            </div> 
                            <span> print </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-puzzle"></i>
                            </div> 
                            <span> puzzle </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-qr-code"></i>
                            </div> 
                            <span> qr-code </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-queen"></i>
                            </div> 
                            <span> queen </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-question-circle"></i>
                            </div> 
                            <span> question-circle </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-question-square"></i>
                            </div> 
                            <span> question-square </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-question"></i>
                            </div> 
                            <span> question </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-quote-left"></i>
                            </div> 
                            <span> quote-left </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-quote-right"></i>
                            </div> 
                            <span> quote-right </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-random"></i>
                            </div> 
                            <span> random </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-recycle"></i>
                            </div> 
                            <span> recycle </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-refresh"></i>
                            </div> 
                            <span> refresh </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-repair"></i>
                            </div> 
                            <span> repair </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-reply-all"></i>
                            </div> 
                            <span> reply-all </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-reply"></i>
                            </div> 
                            <span> reply </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-resize"></i>
                            </div> 
                            <span> resize </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-responsive"></i>
                            </div> 
                            <span> responsive </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-retweet"></i>
                            </div> 
                            <span> retweet </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-road"></i>
                            </div> 
                            <span> road </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-robot"></i>
                            </div> 
                            <span> robot </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-royal"></i>
                            </div> 
                            <span> royal </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-rss-feed"></i>
                            </div> 
                            <span> rss-feed </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-safety"></i>
                            </div> 
                            <span> safety </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-sale-discount"></i>
                            </div> 
                            <span> sale-discount </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-satellite"></i>
                            </div> 
                            <span> satellite </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-send-mail"></i>
                            </div> 
                            <span> send-mail </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-server"></i>
                            </div> 
                            <span> server </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-settings-alt"></i>
                            </div> 
                            <span> settings-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-settings"></i>
                            </div> 
                            <span> settings </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-share-alt"></i>
                            </div> 
                            <span> share-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-share-boxed"></i>
                            </div> 
                            <span> share-boxed </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-share"></i>
                            </div> 
                            <span> share </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-shield"></i>
                            </div> 
                            <span> shield </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-shopping-cart"></i>
                            </div> 
                            <span> shopping-cart </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-sign-in"></i>
                            </div> 
                            <span> sign-in </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-sign-out"></i>
                            </div> 
                            <span> sign-out </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-signal"></i>
                            </div> 
                            <span> signal </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-site-map"></i>
                            </div> 
                            <span> site-map </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-smart-phone"></i>
                            </div> 
                            <span> smart-phone </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-soccer"></i>
                            </div> 
                            <span> soccer </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-sort-alt"></i>
                            </div> 
                            <span> sort-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-sort"></i>
                            </div> 
                            <span> sort </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-space"></i>
                            </div> 
                            <span> space </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-spanner"></i>
                            </div> 
                            <span> spanner </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-speech-comments"></i>
                            </div> 
                            <span> speech-comments </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-speed-meter"></i>
                            </div> 
                            <span> speed-meter </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-spinner-alt-1"></i>
                            </div> 
                            <span> spinner-alt-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-spinner-alt-2"></i>
                            </div> 
                            <span> spinner-alt-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-spinner-alt-3"></i>
                            </div> 
                            <span> spinner-alt-3 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-spinner-alt-4"></i>
                            </div> 
                            <span> spinner-alt-4 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-spinner-alt-5"></i>
                            </div> 
                            <span> spinner-alt-5 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-spinner-alt-6"></i>
                            </div> 
                            <span> spinner-alt-6 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-spinner"></i>
                            </div> 
                            <span> spinner </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-spreadsheet"></i>
                            </div> 
                            <span> spreadsheet </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-square"></i>
                            </div> 
                            <span> square </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ssl-security"></i>
                            </div> 
                            <span> ssl-security </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-star-alt-1"></i>
                            </div> 
                            <span> star-alt-1 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-star-alt-2"></i>
                            </div> 
                            <span> star-alt-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-star"></i>
                            </div> 
                            <span> star </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-street-view"></i>
                            </div> 
                            <span> street-view </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-support-faq"></i>
                            </div> 
                            <span> support-faq </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-tack-pin"></i>
                            </div> 
                            <span> tack-pin </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-tag"></i>
                            </div> 
                            <span> tag </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-tags"></i>
                            </div> 
                            <span> tags </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-tasks-alt"></i>
                            </div> 
                            <span> tasks-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-tasks"></i>
                            </div> 
                            <span> tasks </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-telephone"></i>
                            </div> 
                            <span> telephone </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-telescope"></i>
                            </div> 
                            <span> telescope </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-terminal"></i>
                            </div> 
                            <span> terminal </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-thumbs-down"></i>
                            </div> 
                            <span> thumbs-down </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-thumbs-up"></i>
                            </div> 
                            <span> thumbs-up </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-tick-boxed"></i>
                            </div> 
                            <span> tick-boxed </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-tick-mark"></i>
                            </div> 
                            <span> tick-mark </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-ticket"></i>
                            </div> 
                            <span> ticket </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-tie"></i>
                            </div> 
                            <span> tie </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-toggle-off"></i>
                            </div> 
                            <span> toggle-off </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-toggle-on"></i>
                            </div> 
                            <span> toggle-on </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-tools-alt-2"></i>
                            </div> 
                            <span> tools-alt-2 </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-tools"></i>
                            </div> 
                            <span> tools </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-touch"></i>
                            </div> 
                            <span> touch </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-traffic-light"></i>
                            </div> 
                            <span> traffic-light </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-transparent"></i>
                            </div> 
                            <span> transparent </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-tree"></i>
                            </div> 
                            <span> tree </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-unique-idea"></i>
                            </div> 
                            <span> unique-idea </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-unlock"></i>
                            </div> 
                            <span> unlock </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-unlocked"></i>
                            </div> 
                            <span> unlocked </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-upload-alt"></i>
                            </div> 
                            <span> upload-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-upload"></i>
                            </div> 
                            <span> upload </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-usb-drive"></i>
                            </div> 
                            <span> usb-drive </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-usb"></i>
                            </div> 
                            <span> usb </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-vector-path"></i>
                            </div> 
                            <span> vector-path </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-verification-check"></i>
                            </div> 
                            <span> verification-check </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-wall-clock"></i>
                            </div> 
                            <span> wall-clock </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-wall"></i>
                            </div> 
                            <span> wall </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-wallet"></i>
                            </div> 
                            <span> wallet </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-warning-alt"></i>
                            </div> 
                            <span> warning-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-warning"></i>
                            </div> 
                            <span> warning </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-water-drop"></i>
                            </div> 
                            <span> water-drop </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-web"></i>
                            </div> 
                            <span> web </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-wheelchair"></i>
                            </div> 
                            <span> wheelchair </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-wifi-alt"></i>
                            </div> 
                            <span> wifi-alt </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-wifi"></i>
                            </div> 
                            <span> wifi </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-world"></i>
                            </div> 
                            <span> world </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-zigzag"></i>
                            </div> 
                            <span> zigzag </span>
                        </div>
                    </div>
                    
                    <div className="col">
                        <div className="icon-holder">
                            <div className="icon"> 
                                <i className="icofont-zipped"></i>
                            </div> 
                            <span> zipped </span>
                        </div>
                    </div>
                    
                    
                </div>
            </div>
        </div>
    );
  }
}

export default IconFontsCard;