import React from "react";
import AlertTile from "../../components/UI/AlertTile";

class Alerts extends React.Component {
  render(){
    return(
        <div className="container">
            <AlertTile />
        </div>
    )
  }
}

  
export default Alerts;